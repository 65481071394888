import { useRecoilState, useSetRecoilState } from 'recoil'
import { produce } from 'immer'
import {
  parentFileListAtom,
  childFileListAtom,
  partnershipDetailsListAtom,
  partnerDetailsListAtom,
  investorFilesAtom,
} from '../state/atom'
import { filesClientApi } from '../api-client/investor-portal-client-runtime'
import {
  EmailTemplate,
  LinkInvestorSearchRequest,
  File,
  FileSearchRequest,
  FileStatus,
  FileUpdateRequest,
  ProvisionUsersRequestModel,
  ReprocessFilesRequest,
} from '../api-client/investor-portal-client'
import {
  downloadFileDirectly,
  downloadFileDirectlyByFileName,
  downloadFileFromApi,
  downloadPublicFileFromApi,
  verifyAzureBlobStorageServerAccess,
} from './file-download-api'
import { processNewStatusOnInvestorFiles } from '../components/utility/new-file-session-state'
import { wrapApiWithCommonErrorHandlers } from './api-error-wrapper'
import { getApiCallId, isLatestApiCall } from './api-state-management'
import { FileFlags } from '../api-client/file-flags-override'

export const useFileClientApi = () => {
  const [parentFiles, setParentFiles] = useRecoilState(parentFileListAtom)
  const [childFiles, setChildFiles] = useRecoilState(childFileListAtom)
  const [partnershipDetails, setpartnershipDetails] = useRecoilState(
    partnershipDetailsListAtom
  )
  const [partnerDetails, setPartnerDetails] = useRecoilState(
    partnerDetailsListAtom
  )
  const setInvestorFile = useSetRecoilState(investorFilesAtom)

  /** Get Parent Files based on the client id */
  const getParentFiles = async (
    entityGroupId: number,
    partnershipId: number,
    searchTerm?: string,
    abortSignal?: AbortSignal
  ) => {
    const callId = getApiCallId(getParentFiles)
    try {
      if (
        partnershipId != null &&
        (parentFiles.partnershipId !== partnershipId || searchTerm === '')
      ) {
        setParentFiles(
          produce((draft) => {
            draft.partnershipId = partnershipId
            draft.isLoading = true
            draft.files = []
          })
        )

        //Load parent files from the API based on client id
        const response = await filesClientApi.files_GetByPartnershipEntityId(
          entityGroupId,
          partnershipId,
          false,
          abortSignal
        )

        if (isLatestApiCall(callId)) {
          setParentFiles(
            produce((draft) => {
              draft.partnershipId = partnershipId
              draft.files = response
              draft.isLoading = false
              draft.searchTerm = searchTerm
            })
          )
        }
      }
    } catch (err) {
      if (isLatestApiCall(callId)) {
        setParentFiles(
          produce((draft) => {
            draft.partnershipId = 0
            draft.files = []
            draft.isLoading = false
            draft.searchTerm = ''
          })
        )
      }
      throw err
    }
  }

  /** Get Child Files based on the parent file id */
  const getChildFiles = async (
    parentFileIdValue: number,
    groupIdValue: number,
    abortSignal?: AbortSignal
  ) => {
    const callId = getApiCallId(getChildFiles)

    try {
      if (childFiles.parentFileId !== parentFileIdValue) {
        setChildFiles(
          produce(childFiles, (draft) => {
            draft.parentFileId = parentFileIdValue
            draft.files = []
            draft.isLoading = true
          })
        )
        //Load child files from the API based on parent file id
        const response = await filesClientApi.files_GetChildren(
          parentFileIdValue,
          groupIdValue,
          abortSignal
        )

        if (isLatestApiCall(callId)) {
          setChildFiles(
            produce(childFiles, (draft) => {
              draft.parentFileId = parentFileIdValue
              draft.files = response
              draft.isLoading = false
            })
          )
        }
      }
    } catch (err) {
      if (isLatestApiCall(callId)) {
        setChildFiles(
          produce(childFiles, (draft) => {
            draft.parentFileId = 0
            draft.files = []
            draft.isLoading = false
          })
        )
      }
      throw err
    }
  }

  /** Get Parent Files based on the client id and searchTerm*/
  const getFilteredParentFiles = async (
    entityGroupId: number,
    partnershipId: number,
    searchTerm?: string,
    abortSignal?: AbortSignal
  ) => {
    const callId = getApiCallId(getFilteredParentFiles)

    try {
      if (
        parentFiles.partnershipId !== partnershipId ||
        (searchTerm !== '' && parentFiles.searchTerm !== searchTerm)
      ) {
        setParentFiles(
          produce(parentFiles, (draft) => {
            draft.partnershipId = partnershipId
            draft.files = []
            draft.isLoading = true
            draft.searchTerm = searchTerm
          })
        )

        //Load parent files from the API based on client id and searchTerm
        const searchRequest: FileSearchRequest = {
          clientGroupId: entityGroupId,
          partnershipId: partnershipId,
          searchTerm: searchTerm,
        }
        const response =
          await filesClientApi.files_searchParentFilesByParnershipEntityId(
            searchRequest,
            abortSignal
          )

        if (isLatestApiCall(callId)) {
          setParentFiles(
            produce(parentFiles, (draft) => {
              draft.partnershipId = partnershipId
              draft.files = response
              draft.isLoading = false
              draft.searchTerm = searchTerm
            })
          )
        }
      }
    } catch (err) {
      if (isLatestApiCall(callId)) {
        setParentFiles(
          produce(parentFiles, (draft) => {
            draft.partnershipId = 0
            draft.files = []
            draft.isLoading = false
            draft.searchTerm = ''
          })
        )
      }

      throw err
    }
  }

  /** Calls the API to download files, specified by file ids, from the server.
   *  This downloads multiple files at a time.
   */
  const bulkDownloadFiles = async (files: File[]) => {
    //verify if user able to connect the azure blob storage server
    const useBlobStorage = await verifyAzureBlobStorageServerAccess()

    // Create a list of promises for the downloads of all files being downloaded.
    const promises = new Array<Promise<void>>()

    for (const file of files) {
      // We need to download multiple files, and the endpoint only takes a single
      // file ID.  We'll need to make multiple calls to download files.
      if (useBlobStorage) {
        promises.push(downloadSingleFileForBulk(file))
      } else {
        // If there's a problem downloading from blob storage, then
        // it's probably an issue with the user's network blocking blob URLs.
        // We'll try downloading via proxy through the Investor Portal API.
        // NOTE: We want to download from blob storage first, since this is most efficient.
        promises.push(downloadSingleFileFromApiForBulk(file))
      }
    }

    try {
      // Wait for all files to download.
      await Promise.all(promises)
    } catch (err) {
      // Rethrow the error so the caller can handle showing it to the user or whatever.
      throw err
    }
  }

  async function downloadSingleFileForBulk(file: File): Promise<void> {
    // Get the sas uri.
    const sasUri = await filesClientApi.files_GetDownloadUri(file.id!)

    try {
      // Attempt to download the file directly from Azure.
      await downloadFileDirectly(sasUri, file)

      //Call API to update the download audit in document manager DB
      await filesClientApi.files_AuditDownloadSuccess(file.id!)
    } catch (err) {
      //Call API to update the download audit in document manager DB
      await filesClientApi.files_AuditDownloadFailure(file.id!)

      // Rethrow the error so the caller can handle showing it to the user or whatever.
      throw err
    }
  }

  async function downloadSingleFileFromApiForBulk(file: File): Promise<void> {
    try {
      // Download the file from the InvestorPortal API.
      await downloadFileFromApi(file)

      //Call API to update the download audit in document manager DB
      await filesClientApi.files_AuditDownloadSuccess(file.id!)
    } catch (err) {
      //Call API to update the download audit in document manager DB
      await filesClientApi.files_AuditDownloadFailure(file.id!)

      // Rethrow the error so the caller can handle showing it to the user or whatever.
      throw err
    }
  }

  /** Download the file from azure blob storage. */
  const downloadFile = async (file: File) => {
    // Get the file uri.
    //  Allow any errors to throw here, since we want the caller to handle (not this method).
    let sasUri = await filesClientApi.files_GetDownloadUri(file.id!)

    try {
      // Attempt to download the file directly from Azure.
      await downloadFileDirectly(sasUri, file)

      //Call API to update the download audit in document manager DB
      await filesClientApi.files_AuditDownloadSuccess(file.id!)
    } catch (error) {
      try {
        // If there's a problem downloading from blob storage, then
        // We'll try downloading via proxy through the Investor Portal API.
        await downloadFileFromApi(file)

        //Call API to update the download audit in document manager DB
        await filesClientApi.files_AuditDownloadSuccess(file.id!)
      } catch (err) {
        //Call API to update the download audit in document manager DB
        await filesClientApi.files_AuditDownloadFailure(file.id!)

        // Rethrow the error so it can be processed by the caller.
        throw err
      }
    }
  }

  /** Calls the API to delete a set of files, specified by ids, from the server.
   *   This deletes individual files at a time, and could probably use a batch API for efficiency.
   */
  const deleteFiles = async (fileIds: number[]) => {
    // We need to delete multiple files, and the endpoint only takes a single
    //  file ID.  We'll need to make multiple calls to delete files.
    const deletionPromises = fileIds.map(async (id) => {
      // Delete the file on the server.
      await filesClientApi.files_DeleteFile(id)

      // Update the local store.
      setParentFiles(
        produce((draft) => {
          const fileIndex = draft.files.findIndex((v) => v.id === id)
          draft.files.splice(fileIndex, 1)
        })
      )
    })

    // Wait for all promises to complete.
    await Promise.all(deletionPromises)
  }

  /** Calls the API to delete a file, specified by id  */
  const deleteChildFiles = async (fileIds: number[], parentFileId: number) => {
    // Delete the file on the server.
    const response = await filesClientApi.files_DeleteFiles(fileIds)

    response.forEach((fileResult) => {
      if (fileResult.success) {
        // Update the local store.
        setChildFiles(
          produce((draft) => {
            const fileIndex = draft.files.findIndex(
              (v) => v.id === fileResult.fileId
            )
            draft.files.splice(fileIndex, 1)
          })
        )
      }
    })

    //Update parentfile in recoil state for the childfiles which are being deleted
    await updateParentFileByIdInRecoil(parentFileId)
  }

  /** Set Child file publish flag to true based on the parent id */
  const setChildFilesPublish = (parentFileIdValue: number) => {
    if (childFiles.parentFileId === parentFileIdValue) {
      setChildFiles(
        produce((draft) => {
          draft.files.forEach((f) => {
            if (f.status !== FileStatus.Inactive && f.externalFileId !== 0) {
              f.published = true
            }
          })
        })
      )
    }
  }

  /** Calls the API to publish a set of files, specified by ids*/
  const publishFiles = async (fileIds: number[]) => {
    const response = await filesClientApi.files_PublishFile(fileIds)
    response.forEach((fileResult) => {
      if (fileResult.success) {
        setParentFiles(
          produce((draft) => {
            const fileIndex = draft.files.findIndex(
              (v) => v.id === fileResult.fileId
            )
            if (fileIndex >= 0) {
              draft.files[fileIndex].published = fileResult.success
            }
          })
        )
        // Set Child files Publish flag
        setChildFilesPublish(fileResult.fileId!)
      }
    })
    return response
  }

  /** Calls the API to update the parent file details based on the file id*/
  const updateParentFile = async (
    fileId: number,
    taxYear: number,
    documentSubType: string,
    emailTemplate: string,
    partnershipEntityId: number
  ) => {
    const fileUpdateRequest: FileUpdateRequest = {
      fileId: fileId,
      taxYear: taxYear,
      documentSubType: documentSubType,
      emailTemplate: emailTemplate,
      partnershipEntityId,
    }
    return await filesClientApi.files_UpdateFile(fileUpdateRequest)
  }

  /** Calls the API to re-link a set of files, specified by ids*/
  const relinkFiles = async (fileIds: number[], entityGroupId: number) => {
    const reprocessFilesRequest: ReprocessFilesRequest = {
      fileIds: fileIds,
      entityGroupId: entityGroupId,
    }

    // Passing unique operation ID value as 0, as it's not being used.
    await filesClientApi.files_ReprocessFiles(0, reprocessFilesRequest)
  }

  /** Calls the API to provision users, specified by entityGroupId and clientId*/
  const provisionUsers = async (
    entityGroupId: number,
    clientId: number,
    abortSignal?: AbortSignal
  ) => {
    const provisionUserRequest: ProvisionUsersRequestModel = {
      entityGroupId: entityGroupId,
      clientId: clientId,
    }

    // Requesting client api to start doing provision on users list
    return await filesClientApi.provisionUsers_ProvisionUsers(
      provisionUserRequest,
      abortSignal
    )
  }

  /** Calls the API to provision users for the latest status, specified by entityGroupId and clientId*/
  const getLatestProvisionUsersStatus = async (
    entityGroupId: number,
    clientId: number,
    abortSignal?: AbortSignal
  ) => {
    // Passing entityGroupId, clientId,
    return await filesClientApi.provisionUsers_GetLatestProvisionUserHeader(
      entityGroupId,
      clientId,
      abortSignal
    )
  }

  /** Calls the API to get allocated entities, investors and contacts count*/
  const getEntityPartnershipCount = async (
    entityGroupId: number,
    signal?: AbortSignal | undefined
  ) => {
    // Passing unique operation ID value as 0, as it's not being used.
    return await filesClientApi.entityGroups_GetEntityPartnershipCount(
      entityGroupId,
      signal
    )
  }

  /** Gets client group details*/
  const getClientGroupDetails = async (
    entityGroupId: number,
    taxYear?: string,
    signal?: AbortSignal | undefined
  ) => {
    const callId = getApiCallId(getClientGroupDetails)

    try {
      setpartnershipDetails(
        produce((draft) => {
          draft.details = []
          draft.isLoading = true
        })
      )

      const response = await filesClientApi.entityGroups_GetEntityGroupDetails(
        entityGroupId,
        taxYear,
        signal
      )

      if (isLatestApiCall(callId)) {
        setpartnershipDetails(
          produce(partnershipDetails, (draft) => {
            draft.details = response
            draft.isLoading = false
          })
        )
      }
    } catch (err) {
      if (isLatestApiCall(callId)) {
        produce((draft) => {
          draft.details = []
          draft.isLoading = false
        })
      }

      throw err
    }
  }

  /** Gets client group investor details*/
  const getInvestorDetails = async (
    entityGroupId: number,
    taxYear: string,
    signal?: AbortSignal | undefined
  ) => {
    const callId = getApiCallId(getInvestorDetails)

    try {
      setPartnerDetails(
        produce((draft) => {
          draft.details = []
          draft.isLoading = true
        })
      )

      const response =
        await filesClientApi.entityGroups_GetEntityGroupInvestorDetails(
          entityGroupId,
          taxYear,
          signal
        )

      if (isLatestApiCall(callId)) {
        setPartnerDetails(
          produce(partnerDetails, (draft) => {
            draft.details = response
            draft.isLoading = false
          })
        )
      }
    } catch (err) {
      if (isLatestApiCall(callId)) {
        produce((draft) => {
          draft.details = []
          draft.isLoading = false
        })
      }

      throw err
    }
  }

  /** Gets client group banner stats*/
  const getClientGroupBannerStats = async (
    entityGroupId: number,
    signal?: AbortSignal | undefined
  ) => {
    return await filesClientApi.entityGroups_GetEntityGroupBannerStats(
      entityGroupId,
      signal
    )
  }

  /** Calls the API to get the zip upload tempload template sas-URI, fileName, fileExtention etc.*/
  const getZipUploadTemplate = async (
    entityGroupId: number,
    clientId: number,
    operationId: number
  ) => {
    // Passing entityGroupId, clientId, operationId(operation id is unique number)
    return await filesClientApi.report_GetZipUploadTemplate(
      entityGroupId,
      clientId,
      operationId
    )
  }

  /**Calls API for BulkDownload for entity spreadhseets from Investor Files related entities*/
  const getBulkDownloadTemplateforFiles = async (
    clientGroup: string | null | undefined,
    clientGroupId: number,
    uniqueOperationId: number,
    parentId: number,
    abortSignal?: AbortSignal
  ) => {
    await filesClientApi.report_RequestBulkEntityReport(
      parentId,
      clientGroup,
      clientGroupId,
      uniqueOperationId,
      abortSignal
    )
  }

  /**Calls API for BulkDownload for entity spreadhseets for Entity Manager Entities*/
  const getBulkDownloadTemplateforEntities = async (
    clientGroup: string | null | undefined,
    clientGroupId: number,
    uniqueOperationId: number,
    cacheId: string | undefined,
    filterString?: string,
    abortSignal?: AbortSignal
  ) => {
    await filesClientApi.report_RequestBulkEntityReport2(
      clientGroup,
      clientGroupId,
      uniqueOperationId,
      cacheId!,
      filterString,
      abortSignal
    )
  }

  /** Get entity group states*/
  const getEntityGroupStats = async (
    entityGroupId: number,
    signal?: AbortSignal | undefined
  ) => {
    return await filesClientApi.entityGroups_GetEntityGroupStats(
      entityGroupId,
      signal
    )
  }

  /** Calls the API to get the activity download report sas-URI, fileName, fileExtention etc.*/
  const getInvestorActivityReport = async (
    clientGroupId: number,
    uniqueOperationId: number
  ) => {
    // Passing clientId, operationId(operation id is unique number)
    await filesClientApi.report_RequestInvestorActivityReport(
      clientGroupId,
      uniqueOperationId
    )
  }

  /** Calls the API to update the entity email based on the entity id*/
  const updateEntityEmail = async (entityId: number, emails: string) => {
    await filesClientApi.entity_UpdateEntityEmail(entityId, emails)
  }

  /** Calls the API to get email template */
  const getEmailTemplate = async (
    entityGroupId: number | undefined,
    partnershipEntityId: number | undefined,
    parentFileId: number | undefined,
    abortSignal?: AbortSignal
  ) => {
    return await filesClientApi.emailTemplates_Get(
      entityGroupId,
      partnershipEntityId,
      parentFileId,
      abortSignal
    )
  }

  /** Calls the API to update email template based on id*/
  const updateEmailTemplate = async (
    id: number,
    emailTemplate: EmailTemplate
  ) => {
    return await filesClientApi.emailTemplates_Put(id, emailTemplate)
  }

  /** Calls the API to save email template */
  const saveEmailTemplate = async (emailTemplate: EmailTemplate) => {
    return await filesClientApi.emailTemplates_Post(emailTemplate)
  }

  /** Calls the API to get the partner entities based on the file id*/
  const getPartnerEntities = async (
    fileId: number,
    abortSignal?: AbortSignal
  ) => {
    return await filesClientApi.files_GetPartnerEntities(fileId, abortSignal)
  }

  /** Calls the API to get the partner entities based on the file id*/
  const getAllPartnerEntities = async (
    fileId: number,
    searchRequest: LinkInvestorSearchRequest,
    signal?: AbortSignal | undefined
  ) => {
    return await filesClientApi.files_GetAllPartnerEntities(
      fileId,
      searchRequest,
      signal
    )
  }

  /** Calls the API to update the entitiy based on the file id*/
  const updatePartnerEntityOnFile = async (id: number, entityId: number) => {
    return await filesClientApi.files_UpdatePartnerEntityOnFile(id, entityId)
  }

  /** Calls the API to delete email template based on id*/
  const deleteEmailTemplate = async (id: number) => {
    return await filesClientApi.emailTemplates_Delete(id)
  }

  /**calls the API to fetch investor files (investor view) */
  const getInvestorFiles = async (abortSignal?: AbortSignal) => {
    const callId = getApiCallId(getInvestorFiles)

    try {
      setInvestorFile(
        produce((draft) => {
          draft.files = []
          draft.isLoading = true
        })
      )

      //Load investor files from the API based on client id
      const response = await filesClientApi.files_Get(0, false)

      // This will tell the server that we've received the files, and
      //  And the server should no longer tell us we have new ones.
      const newFileIds = response.filter((x) => x.isNew).map((x) => x.id!)
      if (newFileIds.length > 0) {
        await filesClientApi.files_ClearNewFlagOnFileIds(
          newFileIds,
          abortSignal
        )
      }

      // Update the "new file" status on the incoming files and in session storage.
      //  (Files must show "new" for 24 hours since they were received.)
      processNewStatusOnInvestorFiles(response)

      if (isLatestApiCall(callId)) {
        setInvestorFile(
          produce((draft) => {
            draft.files = response
            draft.isLoading = false
          })
        )
      }
    } catch (err) {
      if (isLatestApiCall(callId)) {
        setInvestorFile(
          produce((draft) => {
            draft.files = []
            draft.isLoading = false
          })
        )
      }

      throw err
    }
  }

  const getInvestorElectionEntities = async (
    investorId: number,
    generalOptionsId: number,
    abortSignal?: AbortSignal
  ) => {
    return await filesClientApi.investorElections_GetEntitiesForInvestorAndGeneralOptions(
      investorId,
      generalOptionsId,
      abortSignal
    )
  }

  /** Get parentfile by File Id and update in recoil state. */
  const updateParentFileByIdInRecoil = async (
    parentFileId: number,
    abortSignal?: AbortSignal
  ) => {
    //Update parentfile in recoil state for the childfiles which are being deleted
    const updatedParentFile = await filesClientApi.files_GetFilesByFileId(
      parentFileId,
      false,
      abortSignal
    )

    setParentFiles((prevParentFiles) =>
      produce(prevParentFiles, (draft) => {
        const parentFileIndex = draft.files.findIndex(
          (file) => file.id === parentFileId
        )
        if (parentFileIndex !== -1) {
          draft.files[parentFileIndex] = updatedParentFile
        }
      })
    )

    return updatedParentFile
  }

  /**Calls API for ZipUploadConfirmation for parent file spreadhseets from Investor Files related entities*/
  const getZipUploadConfirmationReport = async (
    parentId: number | undefined,
    clientGroupId: number,
    uniqueOperationId: number,

  ) => {
    await filesClientApi.report_RequestZipUploadVerificationReport(
      parentId!,
      clientGroupId,
      uniqueOperationId
    )
  }

  /** Updating thte given file flag status for zip verification */
  const updateFileStatus = async (
    parentId: number | undefined,
    fileFlag: FileFlags,

  ) => {
    await filesClientApi.files_UpdateFileFlagStatus(
      parentId!,
      fileFlag
    )
  }

  /** Download public file from azure blob storage. */
  const downloadPubicFile = async (fileName: string) => {
    // Get the file uri.
    //  Allow any errors to throw here, since we want the caller to handle (not this method).
    let sasUri = await filesClientApi.files_GetPublicFileSasUri(fileName)
    try {
      // Attempt to download the public file directly from Azure.
      await downloadFileDirectlyByFileName(sasUri, fileName)
    } catch (error) {
      // If there's a problem downloading from blob storage, then
      // We'll try downloading via proxy through the Investor Portal API.
      await downloadPublicFileFromApi(fileName)
    }
  }
  /** Get the duplicate unmapped files */
  const getDuplicateUnmappedFiles = async (fileIds: number[]) => {
    return await filesClientApi.files_GetDuplicateUnmappedFiles(fileIds)
  }

  /** Deactivate the duplicate Unmapped files and update the parenet files with latest clientInfo */
  const deactivateDuplicateUnmappedFiles = async (fileIds: number[]) => {
    const response = await filesClientApi.files_DeactivateDuplicateUnmappedFiles(fileIds)
    response.forEach((fileResult) => {
      setParentFiles(
        produce((draft) => {
          const fileIndex = draft.files.findIndex(
            (v) => v.id === fileResult.id
          )
          if (fileIndex >= 0) {
            draft.files[fileIndex].clientInfo = fileResult.clientInfo
          }
        })
      )
    })
  }

  /** Get the DMS file location either source or destination 
   * For now this is data we are feching from json file, once API is ready for DMS oprations then we need to remove or modify this
  */
  const getDmsFileLocation = async (clientId: number, taxYear: string, abortSignal?: AbortSignal) => {
    const response = await require('../components/investor-files/dmsFileLocation.json');
   return response.data;
  }

  // Create the object to return the functions in.
  return wrapApiWithCommonErrorHandlers({
    getParentFiles,
    getChildFiles,
    downloadFile,
    bulkDownloadFiles,
    deleteFiles,
    deleteChildFiles,
    getFilteredParentFiles,
    publishFiles,
    updateParentFile,
    relinkFiles,
    provisionUsers,
    getLatestProvisionUsersStatus,
    getEntityPartnershipCount,
    getClientGroupDetails,
    getInvestorDetails,
    getClientGroupBannerStats,
    getZipUploadTemplate,
    getBulkDownloadTemplateforFiles,
    getBulkDownloadTemplateforEntities,
    getEntityGroupStats,
    getInvestorActivityReport,
    updateEntityEmail,
    getEmailTemplate,
    updateEmailTemplate,
    saveEmailTemplate,
    getPartnerEntities,
    updatedPartnerEntityOnFile: updatePartnerEntityOnFile,
    deleteEmailTemplate,
    getInvestorFiles,
    getInvestorElectionEntities,
    getAllPartnerEntities,
    updateParentFileByIdInRecoil,
    getZipUploadConfirmationReport,
    updateFileStatus,
    downloadPubicFile,
    getDuplicateUnmappedFiles,
    deactivateDuplicateUnmappedFiles,
    getDmsFileLocation,
  })
}
