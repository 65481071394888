import React, { useEffect, useMemo, useState } from 'react'
import { Button, DataGrid } from 'devextreme-react'
import './clear-filters-button.scss'
import { Observable, Subject } from 'rxjs'
import { OptionChangedEventInfo } from 'devextreme/core/dom_component'
import dxDataGrid from 'devextreme/ui/data_grid'
import { getLocalStorageValue } from '../../state/local-store'

/** export events to track the datagrid  */
export const useClearFilterButtonEvents = () => {
  
  // Subjects and Observables are key concepts for handling asynchronous or behavioral data
  // Subject is the basic unit of RXJS, subject is a variable that stores value that tied to the component
  // Create a new Subject to track filter changes
  const filterChangedSubject = useMemo(() => new Subject<void>(), [])

  // Convert the subject into an observable to allow other parts of the application to subscribe to it
  const filterChangedObservable = useMemo(
    () => filterChangedSubject.asObservable(),
    [filterChangedSubject]
  )

  /** function to handle onOptionChange Event in the datagrid ,
   *  e - The event object containing information about the option change*/
  const onOptionChangedHandler = (e: OptionChangedEventInfo<dxDataGrid>) => {
    // when there is filter applied on the datagrid, it gets reflected on the column and then the subject changes
    // when there is value on the seachPanel, then the subject changes
    if (e.name === 'columns' || e.name === 'searchPanel') {
      filterChangedSubject.next()
    }
  }

  // Return the observable and the event handler to be used in the component
  return {
    filterChangedObservable,
    onOptionChangedHandler,
  }
}

export interface ClearFiltersButtonProps {
  gridRef: React.RefObject<DataGrid<any, any>>
  onClicked?: () => void
  filterChangedEvent: Observable<void>
  // Filter key is the key of local store that has filterValues for the datagrid
  // In a few cases like (entity-list-page.tsx) the filter is applied after the datagrid is loaded 
  // As a result we see "No filter applied" button even though we have persistant filter
  // In order to display the correct state of the button we fetch the data from local store
  filterKey?: string
  isExternalFilterApplied?: boolean
  clearExternalFilter?: () => void
}

export const ClearFiltersButton = ({
  gridRef,
  onClicked,
  filterChangedEvent,
  isExternalFilterApplied,
  clearExternalFilter,
  filterKey
}: ClearFiltersButtonProps) => {
  const [gridHasFilter, setGridHasFilter] = useState<boolean>(false)

  // Function to check if the filter has an equal operator. Then it should consider as header filter
  const hasEqualOperator = (filter: any): boolean => {    
    if (Array.isArray(filter)) {
      for (let condition of filter) {
        if (Array.isArray(condition) && condition[1] === '=' && !(condition[0] === "isRsmClient")) {
          return true;
        }
        // Recursively check for nested filters
        if (Array.isArray(condition)) {
          const result = hasEqualOperator(condition);
          if (result) 
            return true; 
        }
      }
    }
    return false; 
  };
// Function to check if the grid has any header filter applied
  const hasHeaderFilter = (): boolean => {  
    let hasHeaderFilter = false; 
      
      if (!!gridRef.current) { // Explicitly checking if gridRef.current is truthy
        const combinedFilter = gridRef.current?.instance.getCombinedFilter();
        
        if (combinedFilter) {
          // Iterate through each filter
          for (let i = 0; i < combinedFilter.length; i++) {
            const filter = combinedFilter[i];
            
            if (!Array.isArray(filter)) {
              // Check if the second element is 'contains' or header filter is isRsmClient
              if (!(combinedFilter[1] === 'contains') && !(combinedFilter[0] === "isRsmClient")) {
                hasHeaderFilter = true;
                break; 
              }
            } 
            else if (Array.isArray(filter)) {
              // Check if the header filter condition is met
              hasHeaderFilter = hasEqualOperator(combinedFilter);              
              break; 
              
            }
          }
        }
      }
      return hasHeaderFilter;
  }

  useEffect(() => {
    // Subscribe to the filterChangedEvent observable
    const subscription = filterChangedEvent.subscribe(() => {
      // Update the state to indicate whether the grid has any filters applied    
    setGridHasFilter(() => {    
      // Return the result based on the filter condition or if external filter is applied
      return hasHeaderFilter() || !!isExternalFilterApplied;
    });

  })

    // compute for persistant filter on component mount
    let hasPersistantFilter = false
    // get filters values from local storage
    const storedFilter = getLocalStorageValue(filterKey)
    // if we are having persistent filters for the
    if (storedFilter) {
      //  if the filters are not applied on columns, it would not have "filterValues"
      // "Null" in filterValues mean that, all options are selected in the filter header
      hasPersistantFilter = JSON.parse(storedFilter!).columns.some(
        (x: any) => x.filterValues
      )
    }

    // Initial check to set the grid filter state when the component mounts
    setGridHasFilter(      
      hasHeaderFilter() || !!isExternalFilterApplied || hasPersistantFilter
    )

    // Cleanup function to unsubscribe from the observable when the component unmounts
    return () => {
      subscription.unsubscribe()
    }
  }, [filterChangedEvent, isExternalFilterApplied, gridRef])

  return (
    <>
      <Button
        elementAttr={{
          'data-testid': 'buttonClearFilters',
        }}
        className={
          gridHasFilter
            ? 'button-cursor-clear-filter'
            : 'button-cursor-no-filter-applied'
        }
        data-testid='buttonClearFilters'
        text={gridHasFilter ? 'CLEAR FILTERS' : 'NO FILTER APPLIED'}
        type={gridHasFilter ? 'default' : 'normal'} 
        stylingMode='text'
        onClick={() => {
          if (gridHasFilter) {
            // clearFilter from the data grid if there are filters applied to the datagrid
            gridRef.current?.instance.clearFilter("header")
            if (clearExternalFilter) {
              clearExternalFilter()
            }
            if (onClicked) {
              onClicked()
            }
          }
        }}
      />
    </>
  )
}
