import { Button, CheckBox, ContextMenu } from 'devextreme-react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  LoadingStateEnum,
  smEntityOptionsAtom,
  smGeneralOptionsAtom,
} from '../../state/atom'
import { Grid } from '@mui/material'
import { dgheight, ICellData, MenuProps } from '../../model/file-model'
import {
  DataGrid,
  Column,
  Sorting,
  Scrolling,
  LoadPanel,
  HeaderFilter,
  SearchPanel,
  Selection,
  Item,
  Toolbar,
} from 'devextreme-react/data-grid'
import { useEffect, useRef, useState } from 'react'
import './state-manager-election.scss'
import {
  EntityOptionsModel,
  EntityOptionsView,
  PublishedOptionsStatus,
  SMEntityOptions,
} from '../../api-client/investor-portal-client'
import { useStateManagerApi } from '../../hooks/use-state-manager-api'
import produce from 'immer'
import { ManageElectionSettings } from './manage-election-settings'
import { StatusView } from './status-view'
import {
  EditorPreparingEvent,
  SelectionChangedInfo,
} from 'devextreme/ui/data_grid'
import { GlobalElectionsSetting } from './global-election-settings'
import { SpinnerModal } from '../modal/spinnerModal'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { HeaderFilterDataSourceOptions } from '../../../types/types'
import { CommonFilterHeader } from '../../client-models/clientmodels'
import { ClearFiltersButton, useClearFilterButtonEvents } from '../../common-components/clear-filter/clear-filters-button'

/** Add Status Settings link column */
const statusClick = (cellData: ICellData<EntityOptionsView>) => {
  const publishedDate = cellData.data?.publishedDate
  const minDateStr = '0001-01-01T00:00:00Z'
  if (cellData.rowType === 'data') {
    if (publishedDate && publishedDate.toString() !== minDateStr) {
      return (
        <div>
          <Button
            className=' publish-buttons-complete'
            width={120}
            text='Published'
            type='success'
            stylingMode='outlined'
            style={{ pointerEvents: 'none' }} //for un-clickable button
          />
        </div>
      )
    } else {
      return (
        <div>
          <Button
            className='draft-buttons-incomplete '
            width={120}
            text='Draft'
            type='success'
            stylingMode='outlined'
            style={{ pointerEvents: 'none' }} //for un-clickable button
          />
        </div>
      )
    }
  }
}

/** Set the header filter values */
const statusHeaderFilter = (data: HeaderFilterDataSourceOptions) => {  
  const result = new Array<CommonFilterHeader>()
  data.dataSource.postProcess = (items: CommonFilterHeader[]) => {
    const uniqueMap = new Map<string, CommonFilterHeader>()
    items.forEach((item: CommonFilterHeader) => {
      const value = item.text == '(Blanks)' ? 'Draft' : 'Published'
      if (!uniqueMap.has(value)) {
        uniqueMap.set(value, { text: value, value: value })
      }
    })
    return Array.from(uniqueMap.values())
  }
}
/** Calculate the filter expression for the status column */
function calculateStatusFilterExpression(this: Column, value: any, selectedFilterOperations: string, target: string) {
   
  if (target === 'headerFilter') {
    return [[getStatusByPublishedDate, '=', value]];
  }

  return this.defaultCalculateFilterExpression(value, selectedFilterOperations, target);
}
/** Get the status by the published date */
const getStatusByPublishedDate = (item: EntityOptionsView): string => {
  const minDateStr = '0001-01-01T00:00:00Z'
  return item.publishedDate && item.publishedDate.toString() !== minDateStr ? 'Published' : 'Draft';
}

/** Set value  to current row data for rerendering */
const setCellValue = (
  newData: EntityOptionsView,
  value: boolean,
  currentRowData: EntityOptionsView
) => {
  newData.enableCustomSettings = value
  newData.enableComposites = currentRowData.enableComposites
}

/** Set context menu text and css class */
function renderMenuItem(e: MenuProps) {
  let icon = <span className='material-icons-outlined'>{e.icon}</span>
  switch (e.icon) {
    case 'Publish':
      break
  }
  return (
    <>
      {icon}
      {e.text}
    </>
  )
}

/** State Manager Election Page */
export const StateManagerEntityPage = () => {
  const stateManagerApi = useStateManagerApi()
  const { groupId, taxYear } = useParams() as {
    groupId: string
    taxYear: string
  }
  const groupIdValue = parseInt(groupId)
  const taxYearValue = parseInt(taxYear)
  const dataGridRef = useRef<DataGrid>(null)
  const smgeneralOptions = useRecoilValue(smGeneralOptionsAtom)
  const [entityOptions, setEntityOptions] = useRecoilState(smEntityOptionsAtom)
  const [entityViewData, setEntityViewData] = useState<EntityOptionsView[]>([])
  const [showManageSettings, setShowManageSettings] = useState<boolean>(false)
  const [showStatus, setShowStatus] = useState<boolean>(false)
  const [entityView, setEntityView] = useState<EntityOptionsView>()
  const [isGlobalSettingsVisible, setIsGlobalSettingsVisible] =
    useState<boolean>(false)
  let isEditable = false
  const [enablePublish, setEnablePublish] = useState(false)
  const [allocatingEntityIds, setAllocatingEntityIds] = useState<number[]>()
  const [showPublishSpinner, setShowPublishSpinner] = useState<boolean>(false)
  const [publishPromise, setPublishPromise] = useState<Promise<void>>()
  const commonDialogs = useCommonDialogs()
  const {filterChangedObservable, onOptionChangedHandler } = useClearFilterButtonEvents()

  //Boolean to indicate whether any Allocating entity is published or not in datagrid
  const isAnyPublished = entityOptions.entityOptions.some(
    (x) => x.publishedDate
  )

  const isLoading =
    smgeneralOptions.loadingState !== LoadingStateEnum.Loaded ||
    !entityOptions.hasLoaded
  // Disable the entire data grid if cutoff date is less than today's date
  isEditable = !smgeneralOptions?.generalOptions?.isLocked

  /** Set composites & withholdings based on the enable custom settings flag */
  const setGlobalConfiguration = (entityOptionView: EntityOptionsView[]) => {
    entityOptionView.forEach((options: EntityOptionsView) => {
      // Check if EnableCustomSettings enabled, set from enitty options
      if (options.enableCustomSettings) {
        const entityData = entityOptions.entityOptions.find(
          (e) =>
            e.allocatingEntityId === options.allocatingEntityId &&
            options.id === e.id
        )
        options.enableComposites = entityData?.enableComposites
        options.enableWithholdingExemptions =
          entityData?.enableWithholdingExemptions
      } else {
        // if EnableCustomSettings not enabled, set composite & witholdings from global options
        const smGeneralOptions = smgeneralOptions.generalOptions
        options.enableComposites = smGeneralOptions.composite
        options.enableWithholdingExemptions = smGeneralOptions.witholdings
      }
    })
  }

  const masterContextMenu: MenuProps[] = [
    {
      text: 'Publish',
      icon: 'publish',
      disabled: !isEditable || !enablePublish,
      action: () => {
        // Check if there are any published entities.
        if (isAnyPublished) {
          // If there are published entities, display a warning dialog before proceeding to validate and publish.
          handlePublishedEntitiesWarning(
            smgeneralOptions.generalOptions.id!,
            () => validateAndPublish(allocatingEntityIds!)
          )
        } else {
          // If no entities are published, proceed to validate and publish without displaying a warning.
          validateAndPublish(allocatingEntityIds!)
        }
      },
    },
  ]

  /** When form editablity changes when cut off date is changed it clears all selections
  ,otherwise the items remain checked even when checkboxes are invisible or visible **/
  useEffect(() => {
    dataGridRef.current?.instance.clearSelection()
  }, [isEditable])

  useEffect(() => {
    // Initialize loading flag
    if (
      smgeneralOptions.loadingState !== LoadingStateEnum.Loaded ||
      !entityOptions.hasLoaded
    ) {
      setEntityViewData([])
    }
    if (
      smgeneralOptions.taxYear === taxYearValue &&
      smgeneralOptions.entityGroupId === groupIdValue &&
      smgeneralOptions.loadingState !== LoadingStateEnum.Loading
    ) {
      // No general options available
      if (smgeneralOptions.generalOptions === null) {
        setEntityViewData([])
      } else if (
        // General options available for entity group and tax year
        entityOptions.parentId === smgeneralOptions.generalOptions.id &&
        entityOptions.hasLoaded
      ) {
        // CutOff date is not set then display - setup message
        if (smgeneralOptions.generalOptions.cutoffDate) {
          setEntityOptionsInLocal()
        } else {
          setEntityViewData([])
        }
      }
    }
  }, [
    smgeneralOptions?.loadingState,
    entityOptions,
    taxYearValue,
    groupIdValue,
    smgeneralOptions.generalOptions?.cutoffDate,
    smgeneralOptions.generalOptions?.composite,
    smgeneralOptions.generalOptions?.witholdings,
  ])

  /**Set entity options to local state from recoil state */
  const setEntityOptionsInLocal = () => {
    // Clone recoil state data, otherwise it referes to recoil state data
    // and throw unable to write error
    if (smgeneralOptions.generalOptions?.cutoffDate) {
      const cloneEntityOptions = JSON.parse(
        JSON.stringify(entityOptions.entityOptions)
      )
      // Set composites & withholdings based on the enable custom settings flag
      setGlobalConfiguration(cloneEntityOptions)
      setEntityViewData(cloneEntityOptions)
    }
  }

  /** To save entity options to local state from recoil state when entity options are changed */
  useEffect(() => {
    // setPageState(getDefaultStateManagerState())
    setEntityOptionsInLocal()
  }, [entityOptions?.entityOptions])

  /* Handles the case where entity options for a given general options ID have already been published.
   * onProceedCallback - Callback function to execute when the user chooses to proceed.
   */
  const handlePublishedEntitiesWarning = async (
    generalOptionsId: number,
    onProceedCallback: () => void
  ): Promise<void> => {
    // Retrieve information about published entities and investor count from the server.
    const response: PublishedOptionsStatus =
      await stateManagerApi.entityOptionsHavePublished(generalOptionsId)

    // Check if any entities have been published.
    if (response.anyPublished === true) {
      // Display a confirmation dialog to warn the user about the existing publications and submitted elections.
      commonDialogs.showDialog({
        dialogType: 'general',
        title: 'Already Published',
        content: `One or more of the entities for this tax year have already been published. In addition, ${response.investorCount} investors have already submitted elections. Please note that making changes to settings can impact elections available to or submitted by investors. Are you sure you would like to proceed?`,
        omitDefaultButton: true,
        buttonFunctions: [
          // Cancel button with no action.
          {
            label: 'Cancel',
            isCloseAction: true,
            isDefaultAction: true,
            onClick: () => {},
            buttonProps: {
              stylingMode: 'contained',
              type: 'normal',
              width: 100,
              'data-testid': 'cancel-button',
            },
          },
          // Proceed button that triggers the specified callback function.
          {
            label: 'Proceed',
            isCloseAction: true,
            onClick: () => {
              onProceedCallback()
            },
            buttonProps: {
              stylingMode: 'contained',
              type: 'default',
              width: 110,
              'data-testid': 'proceed-button',
            },
          },
        ],
      })
    }
  }

  /** Call API to update custom settings, composites & with holdings value */
  const handleCheckBoxClick = (
    rowData: { data: SMEntityOptions },
    e: boolean,
    checkBoxName: string
  ) => {
    const optionsModel: EntityOptionsModel = {
      allocatingEntityId: rowData.data.allocatingEntityId,
      parentId: rowData.data.parentId,
      propertyName: checkBoxName,
      propertyValue: e, // Use the checkbox value directly
      id: rowData.data.id,
    }

    // Update entity options in recoil state
    setEntityOptions(
      produce((draft) => {
        draft.parentId = optionsModel.parentId!
        draft.entityOptions.forEach((options: EntityOptionsView) => {
          if (options.allocatingEntityId === optionsModel.allocatingEntityId) {
            options[optionsModel.propertyName as keyof EntityOptionsView] =
              optionsModel.propertyValue as any
          }
        })
      })
    )

    // API call to insert or update SMEntity options
    stateManagerApi.insertorUpdateSMEntityOptions(optionsModel, e)
  }

  /** Disable  Composites and WithholdingExemptions based on the custom settings*/
  const onEditorPreparing = (
    e: EditorPreparingEvent<EntityOptionsView, any>
  ) => {
    if (e.parentType === 'dataRow') {
      if (e.dataField === 'enableCustomSettings') {
        e.editorOptions.disabled = !isEditable
      } else if (e.dataField === 'enableComposites') {
        e.editorOptions.disabled =
          !e.row?.data.enableCustomSettings || !isEditable
      } else if (e.dataField === 'enableWithholdingExemptions') {
        e.editorOptions.disabled =
          !e.row?.data.enableCustomSettings || !isEditable
      }
    }
  }

  /** Add Manage Settings link column */
  const manageSettingsClick = (cellData: ICellData<EntityOptionsView>) => {
    const handleClick = () => {
      setShowManageSettings(true)
      setEntityView(cellData.data!)
    }

    return (
      <Button
        data-role='link-button'
        onClick={() => {
          if (cellData.data?.publishedDate) {
            handlePublishedEntitiesWarning(
              smgeneralOptions.generalOptions.id!,
              handleClick
            )
          } else {
            handleClick()
          }
        }}
        style={{ textTransform: 'none' }}
        stylingMode='outlined'
        disabled={!cellData.data?.enableCustomSettings || !isEditable}
      >
        View/Edit
      </Button>
    )
  }

  /**  Function to handle selection change*/
  const onSelectionChanged = (
    data: SelectionChangedInfo<EntityOptionsView, number>
  ) => {
    // Check if there are any selected items with a minimum date
    const hasItemWithMinimumDate = data.selectedRowsData.some(
      (item: EntityOptionsView) => !item.publishedDate
    )

    // Filter out items that have already been published before setting in state
    const filteredSelectedRowKeys = data.selectedRowKeys.filter(
      (key: number) => {
        const selectedItem = data.selectedRowsData.find(
          (item: EntityOptionsView) => item.allocatingEntityId === key
        )
        // Include items that are not selected, have no published date, or have the minimum date
        return !selectedItem || !selectedItem.publishedDate
      }
    )
    // Enable or disable the publish button based on selection
    const enablePublish =
      filteredSelectedRowKeys.length > 0 || hasItemWithMinimumDate
    if (isEditable) {
      //If the Grid is Editable then only enable Publish
      setEnablePublish(enablePublish)
    }

    // Set the selected allocating entity IDs in state
    setAllocatingEntityIds(filteredSelectedRowKeys)
  }

  /** Function to handle the publish action */
  const handlePublish = async (ids: number[]) => {
    setShowPublishSpinner(true)
    // Set the publish promise using the state manager API
    setPublishPromise(
      stateManagerApi.publishEntityOptions(
        smgeneralOptions.generalOptions?.id!,
        ids
      )
    )
  }

  /** Calls the server, and checks that all allocating entities have at least one selected state.
   *   This will call the handlePublish method if valid or if the user accepts the issue.
   */
  const validateAndPublish = async (ids: number[]) => {
    const dialogId = commonDialogs.showDialog({
      dialogType: 'in-progress',
      content: 'Validating Selections',
      title: 'Please Wait...',
      buttonFunctions: [],
      omitDefaultButton: true,
    })

    // Make the API call to check if this set of items is valid.
    const isPublishValid = await stateManagerApi.entityOptionsHaveStates(
      smgeneralOptions.generalOptions!.id!,
      ids
    )

    // If the operation is valid, then we can just close the dialog.
    if (isPublishValid) {
      // Close the dialog, since we don't need it anymore.
      commonDialogs.closeDialog(dialogId)

      // Execute the publishing of the entities.
      handlePublish(ids)
    } else {
      // There are entities that have no state selections.  Inform the user before we proceed.

      // Get the dialog for future reference.  NOTE: This is not the state
      //  object, so we can alter it.
      const dialogInfo = commonDialogs.getDialogById(dialogId)

      // New warning message.
      dialogInfo.children =
        'You are publishing state elections with no states selected for any investors. Do you wish to proceed?'

      // Change the dialog type.
      dialogInfo.title = 'Warning'
      dialogInfo.dialogType = 'error'

      // Add the buttons.
      dialogInfo.buttonFunctions = [
        {
          label: 'Cancel',
          onClick: () => {
            commonDialogs.closeDialog(dialogId)
          },
        },
        {
          label: 'Proceed',
          buttonProps: {
            type: 'danger',
          },
          onClick: () => {
            commonDialogs.closeDialog(dialogId)
            handlePublish(ids)
          },
        },
      ]

      // Update the dialog.
      commonDialogs.updateDialog(dialogInfo)
    }
  }

  /**  Function to set the action column in a table cell*/
  const setActionColumn = (cellData: ICellData<EntityOptionsView>) => {
    const buttonId = `btnEntityMenuAction-${cellData.data?.allocatingEntityId!}`
    // Check if the item has a publish date different from the minimum date
    const hasPublishDisabled = Boolean(cellData.data?.publishedDate)

    // Buttons for the context menu, with the appropriate actions.
    const contextMenu: MenuProps[] = [
      {
        text: 'Publish',
        icon: 'publish',
        disabled: !isEditable || hasPublishDisabled,
        action: () => {
          const allocatingEntityIds = [cellData.data?.allocatingEntityId!]
          // Check if there are any published entities.
          if (isAnyPublished) {
            // If there are published entities, display a warning dialog before proceeding to validate and publish.
            handlePublishedEntitiesWarning(
              smgeneralOptions.generalOptions.id!,
              () => validateAndPublish(allocatingEntityIds)
            )
          } else {
            // If no entities are published, proceed to validate and publish without displaying a warning.
            validateAndPublish(allocatingEntityIds)
          }
        },
      },
    ]

    // Return the cell content with a button and a menu attached to it.
    return (
      <>
        <Button
          id={buttonId}
          icon='overflow'
          data-testid='context-button'
          stylingMode='text'
        />
        <ContextMenu
          dataSource={contextMenu}
          target={`#${buttonId}`}
          showEvent='click'
          itemRender={renderMenuItem}
          onItemClick={(e) => {
            const item = e.itemData as MenuProps | undefined
            if (item?.action) {
              item.action()
            }
          }}
        />
      </>
    )
  }

  /** Handle Value Changed for Custom Settings,Composites and WitHoldings Checkboxes */
  const handleCheckboxValueChanged = (
    rowData: { data: { publishedDate: Date | undefined } },
    value: boolean,
    actionType: string
  ) => {
    if (rowData.data.publishedDate) {
      // If there is a published date, trigger a warning before executing the checkbox click action.
      handlePublishedEntitiesWarning(smgeneralOptions.generalOptions.id!, () =>
        handleCheckBoxClick(rowData, value, actionType)
      )
    } else {
      handleCheckBoxClick(rowData, value, actionType)
    }
  }

  return (
    <div className='ip-table'>
      <DataGrid
        ref={dataGridRef}
        showBorders={true}
        keyExpr='allocatingEntityId'
        noDataText={
          isLoading
            ? 'Loading...'
            : entityViewData.length === 0
            ? 'Please setup the elections for this tax year with the Global Settings button.'
            : ''
        }
        dataSource={entityViewData}
        columnAutoWidth={false}
        height={dgheight}
        repaintChangesOnly={true}
        remoteOperations={true}
        showColumnLines={false}
        showRowLines={true}
        wordWrapEnabled={false}
        onEditorPreparing={onEditorPreparing}
        onSelectionChanged={onSelectionChanged}
        allowColumnResizing={false}
        onOptionChanged={onOptionChangedHandler}
      >
        <SearchPanel visible={true} width='400px' />
        <Scrolling mode='virtual' rowRenderingMode='virtual' />
        <Selection
          mode='multiple'
          showCheckBoxesMode={isEditable ? 'always' : 'none'}
        />
        <Sorting mode='single' />
        <LoadPanel enabled={false} />
        <HeaderFilter visible={true} allowSearch={true} />
        <Toolbar>
          <Item location='before'>
            <Grid
              container
              item
              xs={12}
              minWidth={1000}
              justifyContent={'flex-start'}
            >
              <Grid item xs={3}>
                {smgeneralOptions.loadingState !== LoadingStateEnum.Loading &&
                  entityOptions.hasLoaded && (
                    <Button
                      text='Global Settings'
                      type='default'
                      stylingMode='contained'
                      onClick={() => {
                        // Check if there are any published entities.
                        if (isAnyPublished) {
                          // If there are published entities, display a warning dialog before proceeding to open Global Setting Modal.
                          handlePublishedEntitiesWarning(
                            smgeneralOptions.generalOptions.id!,
                            () => setIsGlobalSettingsVisible(true)
                          )
                        } else {
                          setIsGlobalSettingsVisible(true)
                        }
                      }}
                      data-testid='sm-global-button'
                    ></Button>
                  )}
              </Grid>
            </Grid>
          </Item>
          <Item name='clearFilters'>
            <ClearFiltersButton
              gridRef={dataGridRef}
              filterChangedEvent={filterChangedObservable}
            />
          </Item>
          <Item name='searchPanel' />
          <Item location='after'>
            <Grid container minWidth={65} justifyContent={'flex-end'}>
              <Button
                stylingMode='outlined'
                id='entityMasterActionBtn'
                data-testid='btnMasterAction'
              >
                <span className='dx-icon-overflow'></span>
              </Button>
              <ContextMenu
                width={150}
                dataSource={masterContextMenu}
                showEvent='mouseenter'
                target='#entityMasterActionBtn'
                itemRender={renderMenuItem}
                onItemClick={(e) => {
                  const item = e.itemData as MenuProps | undefined
                  if (item?.action) {
                    item.action()
                  }
                }}
              />
            </Grid>
          </Item>
        </Toolbar>
        <Column
          dataField='allocatingEntityName'
          caption='Allocating Entity Name'
          allowSearch={true}
          allowSorting={true}
          minWidth={'40%'}
          defaultSortOrder='asc'
        ></Column>
        <Column
          dataField='enableCustomSettings'
          caption='Custom Settings'
          allowSearch={false}
          dataType='boolean'
          allowSorting={true}
          allowFiltering={false}
          width={'14%'}
          setCellValue={setCellValue}
          cellRender={(rowData) => (
            <CheckBox
              value={rowData.data.enableCustomSettings}
              disabled={!isEditable}
              className={!isEditable ? 'disabled-checkbox' : ''}
              onValueChange={(e) =>
                handleCheckboxValueChanged(rowData, e, 'enableCustomSettings')
              }
            />
          )}
        />
        <Column
          caption='Manage Settings'
          cellRender={manageSettingsClick}
          alignment='center'
          allowSearch={false}
          allowSorting={false}
          width={'12%'}
        ></Column>
        <Column
          dataField='enableComposites'
          dataType='boolean'
          caption='Composites'
          allowSearch={false}
          allowSorting={true}
          allowFiltering={false}
          width={'12%'}
          cellRender={(rowData) => (
            <CheckBox
              value={rowData.data.enableComposites}
              disabled={!rowData.data.enableCustomSettings || !isEditable}
              className={
                !rowData.data.enableCustomSettings || !isEditable
                  ? 'disabled-checkbox'
                  : ''
              }
              onValueChange={(e) =>
                handleCheckboxValueChanged(rowData, e, 'enableComposites')
              }
            />
          )}
        />
        <Column
          dataField='enableWithholdingExemptions'
          caption='Withholdings'
          allowSearch={false}
          allowSorting={true}
          allowFiltering={false}
          width={'12%'}
          cellRender={(rowData) => (
            <CheckBox
              value={rowData.data.enableWithholdingExemptions}
              disabled={!rowData.data.enableCustomSettings || !isEditable}
              className={
                !rowData.data.enableCustomSettings || !isEditable
                  ? 'disabled-checkbox'
                  : ''
              }
              onValueChange={(e) =>
                handleCheckboxValueChanged(
                  rowData,
                  e,
                  'enableWithholdingExemptions'
                )
              }
            />
          )}
        />
        <Column
          dataField='publishedDate'
          caption='Status'
          cellRender={statusClick}
          allowSearch={false}
          alignment='center'
          allowSorting={false}
          allowHeaderFiltering={true}
          calculateFilterExpression={calculateStatusFilterExpression}
          width={'14%'}
        >
            <HeaderFilter dataSource={statusHeaderFilter} ></HeaderFilter>
        </Column>
        <Column
          caption='Action'
          cellRender={setActionColumn}
          allowSearch={false}
          allowSorting={false}
          allowFiltering={false}
          width={'8%'}
        />
      </DataGrid>
      {showManageSettings && (
        <ManageElectionSettings
          onClose={() => setShowManageSettings(false)}
          entityOption={entityView!}
        ></ManageElectionSettings>
      )}
      {showStatus && (
        <StatusView
          onCancel={() => setShowStatus(false)}
          entityOption={entityView!}
        ></StatusView>
      )}
      {isGlobalSettingsVisible && (
        <GlobalElectionsSetting
          onComplete={() => setIsGlobalSettingsVisible(false)}
        />
      )}
      <SpinnerModal
        visible={showPublishSpinner}
        errorTitleMessage='Error Saving'
        inProgressTitleMessage='Publishing Entities'
        inProgressMessage='Selected entities are being published...'
        successTitleMessage=''
        successMessage=''
        onClose={() => {
          setShowPublishSpinner(false)
          dataGridRef.current?.instance.clearSelection()
        }}
        apiAction={publishPromise}
      />
    </div>
  )
}
