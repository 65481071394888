import { useEffect, useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { SelectBox } from 'devextreme-react'
import {
  DmsLocationData,
  EngagementName,
  EngagementType,
  FileAvailable,
  OriginalFileLocation,
  WorkspaceType,
} from '../../client-models/dmsFileSelection'
import './originalDmsFileLocation.scss'
import { useFileClientApi } from '../../hooks/use-file-api'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import produce from 'immer'

export interface OriginalDmsFileLocationProps {
  clientId: number
  taxYear: string | undefined
  updateFileLocation: (fileLocation: OriginalFileLocation) => void
  selectedLocation: string
}

/** Original DMS File Location component using for DMS source and destination file locations to store and process file*/
export const OriginalDmsFileLocation = ({
  clientId,
  taxYear,
  updateFileLocation,
  selectedLocation,
}: OriginalDmsFileLocationProps) => {
  const clientApi = useFileClientApi()
  const [dmsLocationData, setDmsLocationData] = useState<DmsLocationData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true) // keepting this default true to show the loading message
  const [fileLocation, setFileLocation] = useState<OriginalFileLocation>({})

  // This timeout only to deplay the data load
  // Once API is ready, this method can be removed
  function timeout(delay: number) {
    return new Promise((r) => setTimeout(r, delay))
  }

  /** Update hte undateFileLocation on every filelocation change */
  useEffect(() => {
    updateFileLocation(fileLocation)
  }, [fileLocation, updateFileLocation])

  useEffect(() => {
    const abortController = new AbortController()
    //Rest the file location and dms location data
    setFileLocation({})
    setDmsLocationData([])
    const getDmsFileLocation = async () => {
      const response = await clientApi.getDmsFileLocation(
        clientId,
        taxYear!,
        abortController.signal
      )
      setDmsLocationData(response)
      // Below timeout is only to delay the data load, remove once API is ready
      await timeout(1500)
      setIsLoading(false)
    }
    getDmsFileLocation()
    return () => {
      abortController.abort(DISMOUNT_ABORT_REASON)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation])

  // Get tax year options
  const taxYearOptions = useMemo(() => {
    const taxYears = dmsLocationData.map((e: DmsLocationData) => e.taxYear)
    if (taxYears.length === 1) {
      setFileLocationValue('taxYear', taxYears[0])
    }
    return taxYears
  }, [dmsLocationData])

  // Set the default selected workspace type if there's only one
  const setWorkspaceTypeIfSingle = (workspaceTypes: string[] | undefined) => {
    if (workspaceTypes?.length === 1) {
      setFileLocationValue('workspaceType', workspaceTypes[0])
    }
  }

  // Get workspace options based on selected taxYear
  const workspaceTypeOptions = useMemo(() => {
    const workspaceTypes = dmsLocationData
      .find((e: DmsLocationData) => e.taxYear === fileLocation.taxYear)
      ?.workspaceTypes.map((w: WorkspaceType) => w.workspaceType)

    // If there's only one workspace type, set it
    setWorkspaceTypeIfSingle(workspaceTypes)

    return fileLocation.taxYear ? workspaceTypes : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileLocation.taxYear])

  // Set the default selected engagement type if there's only
  const setEngagemantTypeIfSingle = (engagementTypes: string[] | undefined) => {
    if (engagementTypes?.length === 1) {
      setFileLocationValue('engagementType', engagementTypes[0])
    }
  }

  // Get engagement type options based on selected workspace and selected taxYear
  const engagementTypeOptions = useMemo(() => {
    const engagementTypes = dmsLocationData
      .find((e: DmsLocationData) => e.taxYear === fileLocation.taxYear)
      ?.workspaceTypes.find(
        (w: WorkspaceType) => w.workspaceType === fileLocation.workspaceType
      )
      ?.engagemantTypes.map((e: EngagementType) => e.engagementType)

    // If there's only one engagement type, set it
    setEngagemantTypeIfSingle(engagementTypes)

    return fileLocation.workspaceType ? engagementTypes : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileLocation.workspaceType])

  // Set the default selected engagement name if there's only one
  const setEngagementNameIfSingle = (engagementNames: string[] | undefined) => {
    if (engagementNames?.length === 1) {
      setFileLocationValue('engagementName', engagementNames[0])
    }
  }

  // Get engagement name options based on selected engagement type
  const engagementNameOptions = useMemo(() => {
    const engagementNames = dmsLocationData
      .find((e: DmsLocationData) => e.taxYear === fileLocation.taxYear)
      ?.workspaceTypes.find(
        (w: WorkspaceType) => w.workspaceType === fileLocation.workspaceType
      )
      ?.engagemantTypes.find(
        (e: EngagementType) => e.engagementType === fileLocation.engagementType
      )
      ?.engagementNames.map((en: EngagementName) => en.engagementName)

    // If there's only one engagement name, set it
    setEngagementNameIfSingle(engagementNames)

    return fileLocation.engagementType ? engagementNames : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileLocation.engagementType])

  // set the default selected file available if there's only one
  const setFileAvailableIfSingle = (fileAvailable: string[] | undefined) => {
    if (fileAvailable?.length === 1) {
      setFileLocationValue('fileAvailable', fileAvailable[0])
    }
  }

  // Get files available options based on selected engagement name
  const filesAvailableOptions = useMemo(() => {
    const filesAvailable = dmsLocationData
      .find((e: DmsLocationData) => e.taxYear === fileLocation.taxYear)
      ?.workspaceTypes.find(
        (w: WorkspaceType) => w.workspaceType === fileLocation.workspaceType
      )
      ?.engagemantTypes.find(
        (e: EngagementType) => e.engagementType === fileLocation.engagementType
      )
      ?.engagementNames.find(
        (en: EngagementName) =>
          en.engagementName === fileLocation.engagementName
      )
      ?.fileAvailable.map((f: FileAvailable) => f.fileName)
    // If there's only one file available, set it
    setFileAvailableIfSingle(filesAvailable)

    return engagementNameOptions ? filesAvailable : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementNameOptions])

  /** Update File location property value in state based on the property name */
  const setFileLocationValue = <
    T extends keyof OriginalFileLocation,
    V extends OriginalFileLocation[T]
  >(propName: T, value: V) => {
    setFileLocation(
      produce((draft) => {
        draft[propName] = value
      })
    )
  }

  return (
    <div className='dms-file-location'>
      {isLoading && (
        <Grid container spacing={1.75}>
          <Grid item xs={8} className='select-box-padding'>
            <span className='header-font dx-field-item-label-text'>
              Loading Files...
            </span>
          </Grid>
        </Grid>
      )}
      {taxYearOptions.length < 1 && !isLoading && (
        <Grid container spacing={1.75}>
          <Grid item xs={8} className='select-box-padding'>
            <span className='header-font'>None Available</span>
          </Grid>
        </Grid>
      )}
      {taxYearOptions.length > 0 && !isLoading && (
        <Grid container spacing={1.75}>
          <Grid item xs={8}>
            <span className='header-font dx-field-item-label-text'>
              Tax Year
            </span>
          </Grid>
          <Grid item xs={12} className='select-box-padding'>
            <SelectBox
              dataSource={taxYearOptions}
              value={fileLocation.taxYear}
              onValueChanged={(e) => {
                setFileLocationValue('taxYear', e.value)
                setFileLocationValue('workspaceType', undefined)
                setFileLocationValue('engagementType', undefined)
                setFileLocationValue('engagementName', undefined)
                setFileLocationValue('fileAvailable', undefined)
              }}
              placeholder='Select Tax Year'
              displayExpr={(item) => item}
              disabled={!taxYear}
            ></SelectBox>
          </Grid>
          <Grid item xs={8}>
            <span className='header-font dx-field-item-label-text'>
              Workspace Type
            </span>
          </Grid>
          <Grid item xs={12} className='select-box-padding'>
            <SelectBox
              dataSource={workspaceTypeOptions}
              value={fileLocation.workspaceType}
              onValueChanged={(e) => {
                setFileLocationValue('workspaceType', e.value)
                setFileLocationValue('engagementType', undefined)
                setFileLocationValue('engagementName', undefined)
                setFileLocationValue('fileAvailable', undefined)
              }}
              placeholder='Select Workspace Type'
              disabled={!fileLocation.taxYear}
              displayExpr={(item) => item}
            ></SelectBox>
          </Grid>

          {/* Engagement Type Selector */}
          <Grid item xs={8}>
            <span className='header-font dx-field-item-label-text'>
              Engagement Type
            </span>
          </Grid>
          <Grid item xs={12} className='select-box-padding'>
            <SelectBox
              dataSource={engagementTypeOptions}
              value={fileLocation.engagementType}
              onValueChanged={(e) => {
                setFileLocationValue('engagementType', e.value)
                setFileLocationValue('engagementName', undefined)
                setFileLocationValue('fileAvailable', undefined)
              }}
              placeholder='Select Engagement Type'
              displayExpr={(item) => item}
              disabled={!fileLocation.workspaceType}
            ></SelectBox>
          </Grid>
          {/* Engagement Name Selector */}
          <Grid item xs={8}>
            <span className='header-font dx-field-item-label-text'>
              Engagement
            </span>
          </Grid>
          <Grid item xs={12} className='select-box-padding'>
            <SelectBox
              dataSource={engagementNameOptions}
              value={fileLocation.engagementName}
              onValueChanged={(e) => {
                setFileLocationValue('engagementName', e.value)
                setFileLocationValue('fileAvailable', undefined)
              }}
              placeholder='Select Engagement'
              displayExpr={(item) => item}
              disabled={!fileLocation.engagementType}
            ></SelectBox>
          </Grid>
          {selectedLocation === 'dms' && (
            <div>
              {/* Available files Selector */}
              <Grid item xs={8}>
                <span className='header-font dx-field-item-label-text'>
                  Files Available
                </span>
              </Grid>
              <Grid item xs={12} className='select-box-padding'>
                <SelectBox
                  dataSource={filesAvailableOptions}
                  value={fileLocation.fileAvailable}
                  onValueChanged={(e) =>
                    setFileLocationValue('fileAvailable', e.value)
                  }
                  placeholder='Select File'
                  displayExpr={(item) => item}
                  disabled={!engagementNameOptions}
                ></SelectBox>
              </Grid>
            </div>
          )}
        </Grid>
      )}
    </div>
  )
}
