import {
  Button,
  CheckBox,
  ContextMenu,
  DataGrid,
  Popover,
} from 'devextreme-react'
import '../../sass/components/grid.scss'
import './entity-list-page.scss'
import { getLandingPageRoute } from '../utility/route-creation'
import { CircularProgress, Grid } from '@mui/material'
import { useNavigate } from '../../hooks/useNavigationGuard'
import {
  Column,
  Editing,
  HeaderFilter,
  Item,
  LoadPanel,
  Paging,
  Scrolling,
  SearchPanel,
  StateStoring,
  Toolbar,
} from 'devextreme-react/data-grid'
import { ICellData, MenuProps } from '../../model/file-model'
import { CounterDisplay } from '../reuasble-components/fileCountDisplay'
import { CopyClipboard } from '../../common-components/copyClipboard'
import SimpleTooltip from '../reuasble-components/simpleTooltip'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { useEffect, useMemo, useRef, useState } from 'react'
import { EntityTypeSelector } from './entity-type-selector'
import {
  BulkUploadDetail,
  BulkUploadEntityManagerState,
  EntityManagerState,
  EntityType,
} from './entity-utils'
import { useEntityClientApi } from '../../hooks/use-entity-api'
import CustomStore from 'devextreme/data/custom_store'
import {
  BulkUploadResult,
  EntitySearchRequest,
  EntityView,
  EntityView2,
  MongoEntityRecord,
  SelectableObjectCacheSelectionState,
  SortOrderTypes,
} from '../../api-client/entity-manager-client-v3'
import { LoadOptions } from 'devextreme/data'
import { ObjectId } from '../../api-client/object-id-override'
import { Link, useLocation, useParams } from 'react-router-dom'
import { EditorOptions } from 'devextreme/ui/editor/editor'
import { ClearFiltersButton, useClearFilterButtonEvents } from '../../common-components/clear-filter/clear-filters-button'
import { AddEditEntity } from './add-edit-entity'
import { CommonDialogButtonFunction } from '../modal/commonDialog/common-dialog-state'
import { useCommonDialogs } from '../modal/commonDialog/common-dialog-operations'
import { SpinnerModal } from '../modal/spinnerModal'
import { ActivtyReport } from '../investor-files/activityReport'
import { BulkDownloadEntitySpreadsheet } from '../investor-files/bulkDownloadEntitySpreadsheets'
import { saveBlob } from '../utility/saveBlobFn'
import { UploadEntityType } from './bulk-upload/upload-entity-type'
import { UploadBulkEntity } from './bulk-upload/upload-bulk-entity'
import { FormattedDialog } from '../modal/formattedDialog'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import { useAuthorizedPermission } from '../../access-manager/use-authorized-permission'
import { permissionRequestClientById } from '../../access-manager/permission-request-creation'
import * as Permissions from '../../model/permissions'
import { useEntityManagerPermissions } from './use-entity-manager-permissions'

/** Set context menu text and css class */
export function renderMenuItem(e: MenuProps) {
  let icon = <span className='material-icons-outlined'>{e.icon}</span>

  switch (e.icon) {
    case 'AddIcon':
      icon = <AddOutlinedIcon />
      break
    case 'GetAppIcon':
      icon = <GetAppOutlinedIcon />
      break
    case 'FileDownload':
      icon = <FileDownloadOutlinedIcon />
      break
    case 'FileUpload':
      icon = <FileUploadOutlinedIcon />
      break
    case 'TextDocument':
      icon = <DescriptionOutlinedIcon />
      break
  }
  return (
    <>
      {icon}
      {e.items ? <span className='dx-icon-spinright' /> : null}
      {e.text}
    </>
  )
}

/** Set the Allocating Entities for the column, if multiple allocating entity found then display
 * Multiple Entities Found as hyperlink and display the Multiple Entity(ies) on mouse over as tooltip
 */
const setAssociatedAllocatingEntity = (
  cellData: ICellData<MongoEntityRecord>
) => {
  const data = cellData.data

  if (cellData.rowType === 'data' && data?.allocatingEntities) {
    if (data?.allocatingEntities.length === 1) {
      return (
        <SimpleTooltip
          displayText={data?.allocatingEntities[0].name}
          popupMessage={data?.allocatingEntities[0].name}
        />
      )
    } else if (data?.allocatingEntities.length === 0) {
      return data.isRSMClient ? (
        <span className='empty-associated-entities'>N/A</span>
      ) : (
        ''
      )
    } else if (data?.allocatingEntities.length > 1) {
      const toolTipArray: string[] = []
      toolTipArray.push(data?.allocatingEntities[0].name!)

      for (let index = 1; index < data?.allocatingEntities.length; index++) {
        let entity = data.allocatingEntities[index].name!
        toolTipArray.push(entity)
        entity += ';' + entity
      }

      return (
        <SimpleTooltip
          displayText={
            <span className='tooltip-string-link'>Multiple Entities Found</span>
          }
          popupMessage={toolTipArray}
        />
      )
    }
  }
  return ''
}

/** Set Allocating Entity column data based on the isRSMClient value */
const setAllocatingEntity = (cellData: ICellData<MongoEntityRecord>) => {
  return cellData.data?.isRSMClient ? 'Yes' : 'No'
}

//check the sort order chosen by user and return
const getSortDirection = (sortOptions: any): SortOrderTypes => {
  return !sortOptions || sortOptions[0].desc === false
    ? SortOrderTypes.Asc
    : SortOrderTypes.Desc
}

//check the sort order chosen by user and return
const getSortField = (sortOptions: any): string | undefined => {
  if (sortOptions) {
    return (
      sortOptions[0].selector.toString().charAt(0).toUpperCase() +
      sortOptions[0].selector.slice(1)
    )
  } else {
    return undefined
  }
}

type DataGridEditorOptions = EditorOptions<CheckBox>
type CheckboxEventHandler = DataGridEditorOptions['onValueChanged']
interface HeaderStores {
  entityNames: CustomStore
  allocatingEntities: CustomStore
  entityTypes: CustomStore
  isRsmClient: CustomStore
  emails: CustomStore
  id: CustomStore
}

/** Entity Manager Table */
export function EntityListPage() {
  const sessionIdRef = useRef<ObjectId | undefined>(undefined)
  const dataGridRef = useRef<DataGrid>(null)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isScrollingAllowed, setIsScrollingAllowed] = useState(true)
  const [popOverTarget, setPopOverTarget] = useState<string>()
  const [popOverVisible, setPopOverVisible] = useState<boolean>(false)
  const [popOverText, setPopOverText] = useState<string | string[]>()

  // If we reset the value then dataStore will refresh
  const [refreshDataStore, setRefreshDataStore] = useState<number>(0)
  const [allEntitySelectionState, setAllEntitySelectionState] =
    useState<SelectableObjectCacheSelectionState>(
      SelectableObjectCacheSelectionState.None
    )
  const currentApiRequestAbortControllerRef = useRef<
    AbortController | undefined
  >()
  // State to manage the currently selected entity type, its data, and the view state.
  const [entityManagerState, setEntityManagerState] =
    useState<EntityManagerState>()

  // State to manage the currently selected entity type, its data, and the view state.
  const [bulkUploadEntityManagerState, setBulkUploadEntityManagerState] =
    useState<BulkUploadEntityManagerState>()

  //Set the entityId which is to be sent to Entity Selector or Add edit Entity Modal component
  //This entity Id gets rewritten in case we use entity selector or editor as we update isRsmclient for New entity
  const [targetEntityId, setTargetEntityId] = useState<number>(0)
  //set True when allocating entity is selected or false
  const [isRSMClient, setIsRSMClient] = useState<boolean>()
  const [showActivityReportModal, setShowActivityReportModal] =
    useState<boolean>(false)

  const [showDeleteApiSpinner, setShowDeleteApiSpinner] =
    useState<boolean>(false)
  //set deletePromise for the API Spinner
  const [deletePromise, setDeletePromise] = useState<Promise<any>>()

  //displaying progress spinner while loading the api
  const [showLoading, setShowLoading] = useState<boolean>(false)

  const commonDialogApi = useCommonDialogs()
  const entityApi = useEntityClientApi()
  const navigate = useNavigate()

  const [showBulkDownloadModal, setShowBulkDownloadModal] = useState(false)

  const [showBulkUploadSuccessModel, setShowBulkUploadSuccessModel] =
    useState(false)
  const [successModalDetails, setSuccessModalDetails] = useState<
    BulkUploadResult | undefined
  >(undefined)

  // Get group id
  const { groupId } = useParams() as {
    groupId: string
  }

  const groupIdValue = parseInt(groupId)

  // Get query string parameter from the URL
  const search = useLocation().search
  const params = new URLSearchParams(search)
  const currentView = params.get('CurrentView')?.toLocaleLowerCase()
  const {filterChangedObservable, onOptionChangedHandler } = useClearFilterButtonEvents()
  const entityManagerPersisitantFilterKey = "entityManager_PersisitantFilter_GroupId:" + groupId

  useAuthorizedPermission([
    permissionRequestClientById(
      Permissions.PAGE_VISIBILITY_ENTITYMAN,
      groupIdValue
    ),
  ])

  useEffect(() => {
    // Cleanup the entity selection cache.
    return () => {
      // Abort any API calls currently in flight.
      if (currentApiRequestAbortControllerRef.current) {
        currentApiRequestAbortControllerRef.current.abort(DISMOUNT_ABORT_REASON)
      }
      if (sessionIdRef.current) {
        entityApi.cleanupServerSideCache(sessionIdRef.current!)
      }
    }
  }, [])

  useEffect(() => {
    /** Apply filter to data grid based on the current view
     * if currentView is All then there's no filter applied to datagrid
     * if currentView is allocatingentity or investor then apply filter to 'Allocating Entity' column
     * Post bulk upload we are refeshing the dataGrid, So kept 'refreshDataStore' in depencey to filter the grid data
     * and show the data for currentView
     */
    if (currentView === 'allocatingentity') {
      dataGridRef.current?.instance.filter(['isRsmClient', '=', 'Yes'])
    } else if (currentView === 'investor') {
      dataGridRef.current?.instance.filter(['isRsmClient', '=', 'No'])
    }
  }, [currentView, refreshDataStore])

  // Function to open the entity type selector view.(For Add New Entity)
  const openEntityTypeSelector = () => {
    setEntityManagerState({
      ...entityManagerState!,
      viewState: 'entity-selector',
    })
  }

    // reset the filterApplied State
    const onClickClearFilterCheck = () => {
      if (currentView === 'allocatingentity') {
        dataGridRef.current?.instance.filter(['isRsmClient', '=', 'Yes'])
      } else if (currentView === 'investor') {
        dataGridRef.current?.instance.filter(['isRsmClient', '=', 'No'])
      }
    }

  /** Set PopOver configuration to display */
  const setPopOverDisplay = (
    showPopOver: boolean,
    text: string | string[],
    target: string
  ) => {
    setPopOverVisible(showPopOver)
    setPopOverText(text)
    setPopOverTarget(target)
  }

  /**Render tooltip text and align center */
  const renderTooltip = () => {
    if (typeof popOverText === 'string') {
      return <div className='content-center'>{popOverText}</div>
    } else {
      return (
        <div className='content-center popup-tooltip'>
          {popOverText!.map((name) => {
            return <div>{name} |</div>
          })}
        </div>
      )
    }
  }

  const permissions = useEntityManagerPermissions(groupIdValue)

  /** Set the email id for the column, if multiple emails found then display
   * Multiple Emails Found as hyperlink and display the email(s) on mouse over as tooltip
   */
  const setEmailAddresses = (cellData: ICellData<MongoEntityRecord>) => {
    const emailList = cellData.data?.emails!

    if (cellData.rowType === 'data' && emailList) {
      const controlId = 'email-' + cellData.data?.id
      let toolTip = emailList[0]

      // Only one mail available
      if (emailList.length === 1) {
        return (
          <div>
            <CopyClipboard
              text={toolTip}
              tooltipText='Email(s) Copied!'
              targetControl={'#' + controlId}
              width={125}
            />
            <span
              id={controlId}
              onMouseEnter={() =>
                setPopOverDisplay(true, toolTip, '#' + controlId)
              }
              onMouseLeave={() => setPopOverDisplay(false, '', '')}
            >
              {toolTip}
            </span>
          </div>
        )
      } // More than one email
      else if (emailList.length > 1) {
        let emails = toolTip

        for (let index = 1; index < emailList.length; index++) {
          const email = emailList[index]
          toolTip += '\n' + email
          emails += ';' + email
        }
        return (
          <div>
            <CopyClipboard
              text={emails}
              tooltipText='Email(s) Copied!'
              targetControl={'#' + controlId}
              width={125}
            />
            <span
              className='tooltip-string-link'
              id={controlId}
              onMouseEnter={() =>
                setPopOverDisplay(true, toolTip, '#' + controlId)
              }
              onMouseLeave={() => setPopOverDisplay(false, '', '')}
            >
              Multiple Emails Found
            </span>
          </div>
        )
      }
    }
    return ''
  }

  // Function to open the entity type editor view.(For Add /Edit Entity Component)
  const openEntityEditor = () => {
    setEntityManagerState({
      ...entityManagerState!,
      viewState: 'entity-editor',
    })
  }

  // Function to handle the selection of an entity type and update the state to Open editor.
  const handleEntityTypeSelected = (selectedType: EntityType) => {
    // Create a new entity object with the updated properties
    const isRsmClient = selectedType !== 'investor'
    const updatedEntity: EntityView = {
      id: 0,
      isRSMClient: isRsmClient!,
    }

    // Update both entityManagerState and entity state
    setEntityManagerState({
      entityType: selectedType,
      entityData: updatedEntity,
      viewState: 'entity-editor',
    })
    setTargetEntityId(0)
    setIsRSMClient(isRsmClient)
  }

  // Function to handle the closing of the entity modal and reset the view state to 'undefined'.
  const onEntityModalClose = () => {
    setEntityManagerState({
      ...entityManagerState!,
      entityType: undefined,
      viewState: undefined,
    })
  }

  /** Reset entity manager state and reload the grid if there's any change in entity data */
  const onAddEditEntityModalClose = (updatedEntity?: EntityView2) => {
    setEntityManagerState({
      ...entityManagerState!,
      entityType: undefined,
      viewState: undefined,
    })

    if (updatedEntity) {
      //refresh the datagrid
      dataGridRef.current?.instance.refresh()
    }
  }

  /** Function to Handle While Deleting the Entiy when Proceed is Clicked*/
  const handleDelete = async (deleteEntityId: number) => {
    if (deleteEntityId) {
      setShowDeleteApiSpinner(true)

      // Call the API to delete the entity by its ID and returns a promise
      let delPromise = entityApi.deleteEntityById(
        sessionIdRef?.current!,
        deleteEntityId!
      )
      // Set the delete promise in state for Api Spinner
      setDeletePromise(delPromise)

      await delPromise.then(() => {
        //refresh the datagrid if the api call is succeeded
        dataGridRef.current?.instance.refresh()
      })
    }
  }

  /** Show Delete Conformation Modal when The Entity is Deleted based on Allocating Entity or Investor*/
  const showDeleteEntityModal = async (
    isRsmClient: boolean,
    entityId: number
  ) => {
    /** Common dialog modal button functions For Deleting Entity */
    const commonDialogButtonFunctions: CommonDialogButtonFunction[] = [
      {
        label: 'CANCEL',
        onClick: () => {},
        isCloseAction: true,
        buttonProps: {
          className: 'cancel-btn-modal',
          stylingMode: 'contained',
          type: 'normal',
          width: 100,
        },
      },
      {
        label: 'Proceed',
        onClick: async () => {
          handleDelete(entityId)
        },
        isCloseAction: true,
        buttonProps: {
          className: 'cancel-btn-modal',
          stylingMode: 'contained',
          type: 'default',
          width: 120,
        },
      },
    ]

    if (isRsmClient) {
      commonDialogApi.showDialog({
        dialogType: 'general',
        title: 'Are you sure?',
        content:
          'Removing an allocating entity has widespread impact and will result in the loss of data. Are you sure you would like to Proceed?',
        buttonFunctions: commonDialogButtonFunctions,
        omitDefaultButton: true,
      })
    } else {
      setShowLoading(true)
      let investorStatusCheck: boolean
      try {
        investorStatusCheck = await entityApi.checkInvestorStatusForDelete(
          entityId
        )
      } catch (err) {
        commonDialogApi.showDialog({
          title: 'Error',
          dialogType: 'error',
          content: `${err}`,
        })
        // Return to prevent showing both error and general modal
        return
      } finally {
        setShowLoading(false)
      }
      // Declare variables to hold dialog title and content
      var title: string = ''
      var content: string = ''

      // Check investorStatusCheck to determine the title and content for the dialog
      if (investorStatusCheck) {
        title = 'Data Loss?'
        content =
          'The investor you are deleting has existing data in the system. If you proceed with deletion all data associated with the investor will be deleted as well. Are you sure you would like to proceed?'
      } else {
        title = 'Are you sure?'
        content =
          'You are removing an investor. Are you sure you would like to Proceed?'
      }

      commonDialogApi.showDialog({
        dialogType: 'general',
        title: title,
        content: content,
        buttonFunctions: commonDialogButtonFunctions,
        omitDefaultButton: true,
      })
    }
  }

  // Function to open the entity type selector view (bulk upload)
  const bulkUploadEntityTypeSelector = () => {
    setBulkUploadEntityManagerState({
      ...bulkUploadEntityManagerState!,
      bulkUploadDetail: undefined,
      viewState: 'entity-selector',
    })
  }

  // Function to handle the selection of an entity type and isOverride before uploading entities.
  const handleEntityTypeAndEmailOverrideChange = (
    selectedType: EntityType,
    isEmailOverride: boolean
  ) => {
    // Create a new entity object with the updated properties
    const isRsmClient = selectedType !== 'investor'
    const updatedUploadDetail: BulkUploadDetail = {
      isRsmClient: isRsmClient,
      clientGroupId: parseInt(groupId),
      overrideEmail: isEmailOverride,
    }

    // Update both entityManagerState and entity state
    setBulkUploadEntityManagerState({
      entityType: selectedType,
      bulkUploadDetail: updatedUploadDetail,
      viewState: 'file-upload',
    })
  }

  // Function to handle post suceessfully bulk upload.
  const handleBulkUploadSuccess = (bulkUploadResult: BulkUploadResult) => {
    setSuccessModalDetails(bulkUploadResult)

    if (sessionIdRef.current) {
      // Calling the cleanup to clear the cached data
      entityApi.cleanupServerSideCache(sessionIdRef.current!)
    }
    // Reset the sessionId
    sessionIdRef.current = undefined

    // Set the refreshDataStore new value so recently uploaded data will also reflect in the grid
    setRefreshDataStore(refreshDataStore + 1)
    setShowBulkUploadSuccessModel(true)
  }

  // Function to handle the closing of the entity modal and reset the view state to 'undefined'.
  const onBulkUploadEntityModalClose = () => {
    setBulkUploadEntityManagerState({
      bulkUploadDetail: undefined,
      entityType: undefined,
      viewState: undefined,
    })
  }

  // Close the bulk upload success model
  const hideBulkUploadSuccessModel = () => {
    setShowBulkUploadSuccessModel(false)
  }

  /**  Function to set the action column in a table cell*/
  const setActionColumn = (cellData: ICellData<MongoEntityRecord>) => {
    const entityId = cellData.data!.id!
    const buttonId = `btnEntityMenuAction-${entityId}`

    // Determine if the user can delete this entity or not.
    const canDelete = cellData.data!.isRSMClient
      ? permissions.canDeleteAllocatingEntity
      : permissions.canDeleteInvestorEntity

    // Buttons for the Action column context menu, with the appropriate actions.
    const contextMenu: MenuProps[] = [
      {
        text: 'Edit',
        icon: 'create',
        action: () => {
          setTargetEntityId(entityId)
          openEntityEditor()
        },
      },
      {
        text: 'Delete ',
        icon: 'delete',
        disabled: !canDelete,
        action: () => {
          showDeleteEntityModal(cellData.data?.isRSMClient!, entityId)
        },
      },
    ]

    // Return the cell content with a button and a menu attached to it.
    return (
      <>
        <Button
          id={buttonId}
          icon='overflow'
          data-testid='context-button'
          stylingMode='text'
        />
        <ContextMenu
          dataSource={contextMenu}
          showEvent='click'
          target={`#${buttonId}`}
          itemRender={renderMenuItem}
          onItemClick={(e) => {
            const item = e.itemData as MenuProps | undefined
            if (item?.action) {
              item.action()
            }
          }}
        />
      </>
    )
  }

  /** Download Blank Bulk Entity Sheet */
  const downloadBlankSheet = async () => {
    const response = await entityApi.downloadBlankTemplate().catch((e) => {
      commonDialogApi.showDialog({
        content: 'Cannot download blank sheet',
        dialogType: 'error',
      })
    })

    if (response && response.data) {
      saveBlob(response.data, 'BulkEntityTemplate.xlsx')
    }
  }

  const setFilterString = () => {
    let filterString = ''
    if (dataGridRef.current && sessionIdRef.current) {
      const filter = dataGridRef.current.instance.getCombinedFilter()
      filterString = filter ? JSON.stringify(filter) : ''
    }
    return filterString
  }

  /** Get datagrid source items */
  const getGridData = () => {
    return (dataGridRef.current?.instance?.getDataSource().items() ??
      []) as MongoEntityRecord[]
  }

  /** DataGrid Master Menu options */
  const masterContextMenu: MenuProps[] = [
    {
      text: 'Add New Entity',
      icon: 'AddIcon',
      disabled: !permissions.canAddNewEntity,
      action: () => {
        openEntityTypeSelector()
      },
    },
    {
      text: 'Download Blank Bulk Sheet',
      icon: 'GetAppIcon',
      disabled: !permissions.canDownloadBlankBulkSheet,
      action: () => {
        downloadBlankSheet()
      },
    },
    {
      text: 'Download Populated Bulk Sheet',
      icon: 'FileDownload',
      disabled:
        allEntitySelectionState === SelectableObjectCacheSelectionState.None ||
        !permissions.canDownloadPopulatedBulkSheet,
      action: () => {
        setShowBulkDownloadModal(true)
      },
    },
    {
      text: 'Upload Bulk Sheet',
      icon: 'FileUpload',
      disabled: !permissions.canUploadAnyBulkSheet,
      action: () => {
        bulkUploadEntityTypeSelector()
      },
    },
    {
      text: 'Investor Activity Report',
      icon: 'TextDocument',
      disabled: !permissions.canDownloadActivityReport,
      action: () => {
        setShowActivityReportModal(true)
      },
    },
  ]

  /** Update all entities based on the selection ie., SelectAll or SelectNone */
  const toggleAllSelectedState: CheckboxEventHandler = (changeInfo) => {
    // We only want to respond to a user event.  If the change happens, because
    //  another checkbox was checked (but not the "Select All" checkbox), then
    //  we want to exit.
    if (!changeInfo.event) {
      return
    }

    changeInfo.event?.preventDefault()

    // Determine the new state for the selection.
    const isSelected = changeInfo.value

    // Get the grid data.
    const gridData = getGridData()

    if (sessionIdRef.current) {
      // Create the filter string, if we have one.
      const filter = dataGridRef.current!.instance.getCombinedFilter()
      let filterString = filter ? JSON.stringify(filter) : ''

      // Block the scrolling on the page until the database is updated.
      setIsScrollingAllowed(false)
      entityApi
        .setAllEntitySelectionStatus(
          sessionIdRef.current!,
          isSelected,
          filterString
        )
        .then((x) => {
          setIsScrollingAllowed(true)
        })
    }

    // Make the update instructions for the data grid data.
    const changes = gridData
      .filter((x) => x.isSelected !== isSelected)
      .map((x) => ({
        type: 'update' as const,
        data: { isSelected: isSelected },
        key: x.id!,
      }))

    // Make the actual update to the data.
    dataStore.push(changes)

    // Update the selection state.
    const newSelectionState = isSelected
      ? SelectableObjectCacheSelectionState.All
      : SelectableObjectCacheSelectionState.None
    setAllEntitySelectionState(newSelectionState)
  }

  /** custom store */
  const dataStore = useMemo(() => {
    // We define our search request here so we can reuse it for filtering
    //  when checking if all entities are checked.
    let searchRequest: EntitySearchRequest | undefined = undefined

    // Abort controller for getting the state of "All Entities Checked".
    //  We want to be able to cancel this on subsequent calls, when calls are still in flight.
    let allEntitiesCheckedAbortController: AbortController | undefined =
      undefined

    /** Loads the entity selection data from the server. */
    const loadEntityHandler = async (
      loadOptions: LoadOptions<MongoEntityRecord>
    ) => {
      const newAbortcontroller = new AbortController()
      currentApiRequestAbortControllerRef.current = newAbortcontroller

      // Set loading to true to display loading in UI
      setIsLoading(true)

      // Search request object
      searchRequest = {
        sessionId: sessionIdRef.current,
        clientGroupId: parseInt(groupId),
        sortDirection: getSortDirection(loadOptions.sort),
        sortField: getSortField(loadOptions.sort),
        filterData: JSON.stringify(loadOptions.filter) === 'null' ? undefined : JSON.stringify(loadOptions.filter),
        pageSize: loadOptions['take'],
        skipCount: loadOptions['skip'],
      }

      // Make API call and set data in store
      const responseData = await entityApi.getEntities(
        searchRequest!,
        newAbortcontroller.signal
      )

      // Set session id to use it in subsequent API calls
      sessionIdRef.current = responseData.sessionId

      // Set All EntitySelectionState in state to set the 'Select All' check box
      setAllEntitySelectionState(responseData.allSelectedState!)

      // Set loading flag to false
      setIsLoading(false)
      setTotalCount(responseData.totalCount!)
      return {
        data: responseData.data!,
        totalCount: responseData.totalCount!,
      }
    }

    /** Updates the selection state of a specified entity locally, as well as on the server. */
    const updateEntitySelection = async (
      key: number,
      newValue: MongoEntityRecord
    ) => {
      // Update the selection state on the server.
      await entityApi.setEntitySelectionState(
        sessionIdRef.current!,
        key,
        newValue.isSelected!
      )

      // If we were already checking the state of "All Entities Checked", then
      //  cancel that request, since we're going to start a new one.
      allEntitiesCheckedAbortController?.abort(DISMOUNT_ABORT_REASON)

      // Create a new abort controller for this request.
      allEntitiesCheckedAbortController = new AbortController()

      // Get the new "All Selected" state, based on the last request filter.
      //  There's no way searchRequest can be undefined if we're able to make selections.
      searchRequest!.sessionId = sessionIdRef.current!
      searchRequest!.filterData = JSON.stringify(
        dataGridRef.current!.instance.getCombinedFilter()
      )

      try {
        const isAllSelected = await entityApi.getSelectionState(
          sessionIdRef.current!,
          allEntitiesCheckedAbortController.signal
        )

        setAllEntitySelectionState(isAllSelected)
      } catch (err) {
        // We want to throw errors if it's not from abort.
        if ((err as any).status) {
          throw err
        }
      }
    }

    return new CustomStore({
      key: 'id',
      load: loadEntityHandler,
      update: (_, __) => {
        return Promise.resolve()
      },
      onUpdated: (key, newValue) => {
        updateEntitySelection(key, newValue)
      },
      onModified: () => {},
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, currentView, refreshDataStore])

  /** The datastore used to drive the header filter. */
  const headerFilterDataStores = useMemo(() => {
    /** Function to create the function to load the header options from the server for a given property. */
    const createLoadMethod = (propName: string) => {
      return async function (options: LoadOptions<string>) {
        if (
          typeof options.skip !== 'number' ||
          typeof options.take !== 'number'
        ) {
          throw new Error(
            `Filter options request must include a skip and take property.`
          )
        }

        const filterOptions = setFilterString()
        // Get filter options based on the column
        const response = await entityApi.getFilterOptions(
          sessionIdRef.current!,
          propName,
          options.skip!,
          options.take!,
          filterOptions,
          currentView === 'all'
            ? undefined
            : currentView === 'allocatingentity'
            ? true
            : false
        )

        return {
          data: response.data!.map((x) => ({
            text: x,
            value: x,
          })),
          totalCount: response.totalCount!,
        }
      }
    }

    // Assemble the stores for each property.
    const storeProps: (keyof HeaderStores)[] = [
      'entityNames' as const,
      'allocatingEntities' as const,
      'entityTypes' as const,
      'isRsmClient' as const,
      'emails' as const,
      'id' as const,
    ]

    const results: HeaderStores = {} as HeaderStores
    storeProps.forEach((x) => {
      results[x] = new CustomStore({
        load: createLoadMethod(x),
      })
    })

    return results
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView])

  return (
    <div className='entitymanager-table ip-table'>
      <DataGrid
        ref={dataGridRef}
        showBorders={true}
        noDataText={
          isLoading ? 'Loading...' : totalCount === 0 ? 'No Data Found' : ''
        }
        dataSource={dataStore}
        columnAutoWidth={true}
        height='calc(90vh - 152px)'
        width='100%'
        repaintChangesOnly={true}
        remoteOperations={true}
        showColumnLines={false}
        showRowLines={true}
        wordWrapEnabled={false}
        disabled={!isScrollingAllowed}
        onOptionChanged={onOptionChangedHandler}
      >
        <StateStoring
          enabled={true}
          type='localStorage'
          storageKey={entityManagerPersisitantFilterKey}
        />
        <Paging defaultPageSize={13} />
        <SearchPanel visible={true} width='400px' />
        <Scrolling mode='virtual' rowRenderingMode='virtual' />
        <LoadPanel enabled={true} />
        <Toolbar>
          <Item location='before'>
            <Grid
              container
              item
              xs={12}
              minWidth={1000}
              justifyContent={'flex-start'}
            >
              <Grid item xs={0.5}>
                <Button
                  id='back-button-entity'
                  onClick={() => navigate(getLandingPageRoute())}
                  stylingMode='outlined'
                  data-testid='Backbutton'
                >
                  <span className='dx-icon-arrowleft'></span>
                </Button>
              </Grid>
              <Grid item xs={3} className='count-display'>
                <CounterDisplay
                  count={totalCount}
                  isVisible={true}
                  title='Entity Manager'
                />
              </Grid>
            </Grid>
          </Item>
          <Item name='clearFilters'>
            <ClearFiltersButton
              gridRef={dataGridRef}
              onClicked={onClickClearFilterCheck}
              filterChangedEvent={filterChangedObservable}
              filterKey={entityManagerPersisitantFilterKey}
            />
          </Item>
          <Item name='searchPanel' />
          <Item location='after'>
            <Grid container minWidth={50} justifyContent={'flex-end'}>
              <Button
                stylingMode='outlined'
                id='entityMasterActionBtn'
                data-testid='btnMasterAction'
              >
                <span className='dx-icon-overflow'></span>
              </Button>
              <ContextMenu
                width={150}
                dataSource={masterContextMenu}
                showEvent='mouseenter'
                target='#entityMasterActionBtn'
                itemRender={renderMenuItem}
                onItemClick={(e) => {
                  const item = e.itemData as MenuProps | undefined
                  if (item?.action) {
                    item.action()
                  }
                }}
              />
            </Grid>
          </Item>
        </Toolbar>
        <Editing
          mode='cell'
          allowUpdating={true}
          allowAdding={false}
          refreshMode='repaint'
        />
        <HeaderFilter visible={true} width='100%' />
        <Column
          dataField='isSelected'
          dataType='boolean'
          headerCellRender={() => {
            return (
              <CheckBox
                data-testid='chk-select-all'
                disabled={totalCount === 0 || isLoading}
                value={
                  allEntitySelectionState ===
                  SelectableObjectCacheSelectionState.All
                }
                onValueChanged={toggleAllSelectedState as any}
              />
            )
          }}
          allowEditing={true}
          allowSearch={false}
          allowSorting={false}
          allowFiltering={false}
          width={'3%'}
        ></Column>
        <Column
          caption='Allocating Entity'
          dataField='isRsmClient'
          dataType='boolean'
          width={'11%'}
          allowSorting={currentView === 'all' ? true : false}
          cellRender={setAllocatingEntity}
          alignment='left'
          allowEditing={false}
          allowFiltering={false}
        >
          <HeaderFilter
            dataSource={headerFilterDataStores.isRsmClient}
          ></HeaderFilter>
        </Column>
        <Column
          caption='Entity ID'
          dataField='id'
          dataType='string'
          allowSorting={true}
          allowSearch={true}
          width={'10%'}
          allowEditing={false}
          allowFiltering={false}
        >
          <HeaderFilter dataSource={headerFilterDataStores.id}></HeaderFilter>
        </Column>
        <Column
          caption='Entity Name'
          dataField='name'
          dataType='string'
          allowSorting={true}
          allowSearch={true}
          width={'22%'}
          allowEditing={false}
        >
          <HeaderFilter
            dataSource={headerFilterDataStores.entityNames}
          ></HeaderFilter></Column>
          <Column
          dataField='firstName'
          dataType='string'
          allowSearch={true}
          visible={false}
          />
          <Column
          dataField='lastName'
          dataType='string'
          allowSearch={true}
          visible={false}
          />
          <Column
          dataField='entityShortName'
          dataType='string'
          allowSearch={true}
          visible={false}
          />
          <Column
          dataField='partnerSightName'
          dataType='string'
          allowSearch={true}
          visible={false}
          />
          <Column
          dataField='middleInitial'
          dataType='string'
          allowSearch={true}
          visible={false}
          />
          <Column
          dataField='clientId'
          dataType='string'
          allowSearch={true}
          visible={false}
          />
          <Column
          dataField='matchingCriteria'
          dataType='string'
          allowSearch={true}
          visible={false}
          />
          <Column
          dataField='identificationNumber'
          dataType='string'
          allowSearch={true}
          visible={false}
          />
          <Column
          dataField='nameContinued'
          dataType='string'
          allowSearch={true}
          visible={false}
          />
        <Column
          caption='Associated Allocating Entities'
          dataField='entityAllocatingEntities'
          dataType='string'
          cellRender={setAssociatedAllocatingEntity}
          allowSorting={true}
          width={'21%'}
          allowEditing={false}
          allowFiltering={currentView === 'allocatingentity' ? false : true}
        >
          <HeaderFilter
            dataSource={headerFilterDataStores.allocatingEntities}
          ></HeaderFilter>
        </Column>
        <Column
          dataField='entityType'
          caption='Entity Type'
          dataType='string'
          allowSorting={true}
          width={'12%'}
          allowEditing={false}
        >
          <HeaderFilter
            dataSource={headerFilterDataStores.entityTypes}
          ></HeaderFilter>
        </Column>
        <Column
          dataField='investorEmails'
          caption='Email Address'
          dataType='string'
          allowSorting={true}
          cellRender={setEmailAddresses}
          data-includeTooltip={false}
          width={'23%'}
          allowFiltering={true}
          allowSearch={true}
          allowEditing={false}
        >
          <HeaderFilter
            dataSource={headerFilterDataStores.emails}
          ></HeaderFilter>
        </Column>
        <Column
          caption='Action'
          allowSearch={false}
          allowSorting={false}
          allowFiltering={false}
          cellRender={setActionColumn}
          width={'4%'}
          alignment='center'
          allowEditing={false}
        />
        <Column
          dataField='searchableIdentificationNumber'
          dataType='string'
          allowSearch={true}
          allowSorting={false}
          allowFiltering={false}
          visible={false}
        />
      </DataGrid>
      {entityManagerState?.viewState === 'entity-selector' && (
        <EntityTypeSelector
          onEntityTypeSelected={handleEntityTypeSelected}
          onCancel={onEntityModalClose}
        />
      )}
      {entityManagerState?.viewState === 'entity-editor' && (
        <AddEditEntity
          onModalClose={onAddEditEntityModalClose}
          entityId={targetEntityId}
          isRsmClient={isRSMClient}
          groupId={parseInt(groupId)}
          cacheId={sessionIdRef.current!}
        />
      )}
      {bulkUploadEntityManagerState?.viewState === 'entity-selector' && (
        <UploadEntityType
          onEntityTypeAndOverrideSelected={
            handleEntityTypeAndEmailOverrideChange
          }
          onCancel={onBulkUploadEntityModalClose}
        />
      )}
      {bulkUploadEntityManagerState?.viewState === 'file-upload' && (
        <UploadBulkEntity
          uploadDetail={bulkUploadEntityManagerState.bulkUploadDetail!}
          onSuccess={handleBulkUploadSuccess}
          onCancel={onBulkUploadEntityModalClose}
        />
      )}
      <Popover
        target={popOverTarget}
        visible={popOverVisible}
        width={280}
        contentRender={renderTooltip}
      ></Popover>
      {showActivityReportModal && (
        <ActivtyReport
          entityGroupId={parseInt(groupId)}
          onCancel={() => setShowActivityReportModal(false)}
          onConfirm={() => setShowActivityReportModal(false)}
        ></ActivtyReport>
      )}
      <SpinnerModal
        visible={showDeleteApiSpinner}
        errorTitleMessage='Unable to Delete'
        inProgressTitleMessage='Deleting entity'
        inProgressMessage='Deleting Entity'
        successTitleMessage='Entity deleted successfully'
        successMessage='Entity deleted successfully.'
        onClose={() => {
          setShowDeleteApiSpinner(false)
        }}
        closeOnSuccess={true}
        apiAction={deletePromise}
      />
      {showLoading && (
        <CircularProgress
          className='loadapi-investor-status'
          color='info'
          size='40px'
        />
      )}
      {showBulkDownloadModal && (
        <BulkDownloadEntitySpreadsheet
          isVisible={showBulkDownloadModal}
          onCancel={() => {
            setShowBulkDownloadModal(false)
          }}
          onConfirm={() => {
            setShowBulkDownloadModal(false)
          }}
          cacheId={sessionIdRef.current!}
          filterString={setFilterString()!}
        ></BulkDownloadEntitySpreadsheet>
      )}

      {showBulkUploadSuccessModel && (
        <FormattedDialog
          visible={true}
          dialogType='success'
          title='Upload Successful'
          onCloseClicked={hideBulkUploadSuccessModel}
        >
          <div>
            <div>
              {successModalDetails!.insertions} entities successfully added.
            </div>
            <div>
              {successModalDetails!.updates} duplicates were found and updated.
            </div>
          </div>
        </FormattedDialog>
      )}
    </div>
  )
}
