import dxCheckBox, {
  InitializedEvent,
  ValueChangedEvent,
} from 'devextreme/ui/check_box'
import dxDataGrid, {
  EditorPreparingEvent,
  SelectionChangedEvent,
} from 'devextreme/ui/data_grid'
import { useCallback, useRef } from 'react'

// See https://supportcenter.devexpress.com/ticket/details/t869704/datagrid-for-devextreme-how-to-disable-selecting-certain-rows#React/src/App.tsx

/** Returns the methods needed to optionally disable selection checkboxes on the datagrid.
 *   onSelectionChanged and onEditorPreparing methods can optionally be supplied to be called along
 *   with those supplied by this hook. */
export const useDisableCheckboxes = <T, TIndex = number>(
  isItemSelectionDisabled: (item: T) => boolean,
  onSelectionChangedAddition?: (e: SelectionChangedEvent<T, TIndex>) => void,
  onEditorPreparingAddition?: (e: EditorPreparingEvent<T, TIndex>) => void
) => {
  // Persistent reference to indicate whether or not the checkboxes
  //  are being changed by the operations in this hook.
  const updatingCheckboxRef = useRef(false)

  // Reference to hold the checkbox used to "select all".
  const selectAllCheckboxRef = useRef<dxCheckBox | null>(null)

  const isSelectAll = useCallback(
    (dataGrid: dxDataGrid<T, TIndex>) => {
      let items = dataGrid.getDataSource().items()

      const selectableItems = items.filter((x) => !isItemSelectionDisabled(x))
      const selectedRowKeys = dataGrid.option('selectedRowKeys')

      if (!selectedRowKeys || !selectedRowKeys.length) {
        return false
      }

      const result =
        selectedRowKeys.length >= selectableItems.length ? true : undefined

      return result
    },
    [isItemSelectionDisabled]
  )

  const onSelectionChanged = useCallback(
    (e: SelectionChangedEvent<T, TIndex>) => {
      //  This aids in making a row unselected when the 'select all' button is clicked, but the row is not
      //  supposed to be selected.
      const deselectRowKeys: TIndex[] = []

      // Ensure that we're not checking any item that's not supposed to be checked.
      e.selectedRowsData.forEach((item) => {
        if (isItemSelectionDisabled(item))
          deselectRowKeys.push(e.component.keyOf(item))
      })

      // If there are any rows to deselect, then do so.
      if (deselectRowKeys.length) {
        e.component.deselectRows(deselectRowKeys)
      }

      updatingCheckboxRef.current = true
      selectAllCheckboxRef.current!.option('value', isSelectAll(e.component))
      updatingCheckboxRef.current = false

      // Honor the selection changed provided by the user, if there is one.
      if (onSelectionChangedAddition) {
        onSelectionChangedAddition(e)
      }
    },
    [isSelectAll, onSelectionChangedAddition, isItemSelectionDisabled]
  )

  const onEditorPreparing = useCallback(
    (e: EditorPreparingEvent<T, TIndex>) => {
      let dataGrid = e.component

      // Not sure why e doesn't have the type property on it, but it does, and we'll have to trick it.
      //  We only care about selection values, so exit if this isn't that type of column.
      if ((e as any).type !== 'selection') {
        return
      }

      // Here, we're updating individual row selection checkboxes.
      //  Disable them if this row is to be disabled.
      if (
        e.parentType === 'dataRow' &&
        e.row &&
        isItemSelectionDisabled(e.row.data)
      ) {
        e.editorOptions.disabled = true
      }

      if (e.parentType === 'headerRow') {
        // Set the initialization function so that we capture the "select all" checkbox in a ref.
        e.editorOptions.onInitialized = (e: InitializedEvent) => {
          if (e.component) {
            selectAllCheckboxRef.current = e.component
          }
        }

        // Set the state of the select all checkbox.
        e.editorOptions.value = isSelectAll(dataGrid)

        // Update the handler for when the select all button changes.
        e.editorOptions.onValueChanged = (e: ValueChangedEvent) => {
          // If there's no event, then this is not triggered by the user, but by the changes
          //  in the child items.  We don't want this checkbox to change on every update to the
          //  children, so we switch it back to whatever it was before.  We only want
          //  changes when the user triggers the change.
          if (!e.event) {
            if (e.previousValue && updatingCheckboxRef.current) {
              e.component.option('value', e.previousValue)
            }

            return
          }

          // If the child selections already equal the "select all" value
          //  the there's nothing we have to do.  Exit now.
          if (isSelectAll(dataGrid) === e.value) {
            return
          }

          // Depending on what the user selected, make all of the
          //  child checkboxes follow suit.
          e.value ? dataGrid.selectAll() : dataGrid.deselectAll()
          e.event.preventDefault()
        }
      }

      // Honor the editor preparing method if one was provided by the user.
      if (onEditorPreparingAddition) {
        onEditorPreparingAddition(e)
      }
    },
    [isItemSelectionDisabled, isSelectAll, onEditorPreparingAddition]
  )

  // Return the results:
  return {
    onEditorPreparing,
    onSelectionChanged,
  }
}
