import { ReactNode, useRef, useState } from 'react'
import { Grid } from '@mui/material'
import { RequiredRule } from 'devextreme-react/form'
import {
  DataGrid,
  Column,
  HeaderFilter,
  Selection,
  Scrolling,
  SearchPanel,
  LoadPanel,
  Sorting,
  PatternRule,
} from 'devextreme-react/data-grid'
import './manageExistingInvestors.scss'
import { useUserApi } from '../../hooks/use-user-api'
import { dgheight, ICellData, MenuProps } from '../../model/file-model'
import { addToolTipOnCellPrepared } from '../utility/column-utility'
import { multipleEmailWithoutWildcardPattern } from '../../utilities/email-validation'
import { ClearFiltersButton, useClearFilterButtonEvents } from '../../common-components/clear-filter/clear-filters-button'
import { Button, ContextMenu, TextBox, Validator } from 'devextreme-react'
import { ValueChangedEvent } from 'devextreme/ui/text_box'
import {
  ExistingInvestor,
  ProvisionRequestDTO,
  ProvisionUserHeader,
} from '../../api-client/investor-portal-client'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined'
import { CemInfoModal } from './cemInfoModal'
import * as Permissions from '../../model/permissions'
import { useAuthorizedPermission } from '../../access-manager/use-authorized-permission'
import {
  permissionRequestAny,
} from '../../access-manager/permission-request-creation'
import { EventInfo } from 'devextreme/events'
import dxDataGrid, { SelectionChangedEvent } from 'devextreme/ui/data_grid'
import { useManageExistingInvestorsFunctions } from './useManageExistingInvestorsPermissionsFunctions'
import { ProvisionUserByEmailsWatcher } from '../../signalr/watcher/provision-user-watcher-by-emails'
import { isProvisionUserHeaderList } from '../utility/type-guards'
import { provisionUsersStatusMessages } from './provision-users-common-items'
import { ModalDimensionProps } from './provisionUserByEmails'
import { SpinnerModal } from '../modal/spinnerModal'
import { ProvisionUsersFailedStatus } from './provisionUsersFailedStatus'
import { AddEditEntity } from '../entity-manager/add-edit-entity'
import produce from 'immer'
import { EntityView2 } from '../../api-client/entity-manager-client-v3'
import { OptionChangedEventInfo } from 'devextreme/core/dom_component'

// create a new type as the response data does not have any value that is suitable to assign as a key to datagrid
// assigning any value  from response would lead to flickeringf issue
// in prder to avoid that we create a unique key that is rowId
type ExistingInvestorWithUniqueId = {
  existingInvestor: ExistingInvestor
  uniqueId: number
}

/** Set context menu text and css class */
export function renderMenuItem(e: MenuProps) {
  return (
    <>
      <span className='material-icons-outlined'>{e.icon}</span>
      {e.items ? <span className='dx-icon-spinright' /> : null}
      {e.text}
    </>
  )
}

/** Manage Existing Inverstors Componenet */
export const ManageExistingInvestors = () => {
  const [emailAddress, setEmailAddress] = useState<string>()
  // set the email address which was already searched.
  const [searchedEmailAddress, setSearchedEmailAddress] = useState<string>()
  const validatorRef = useRef<Validator>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [editEntityData, setEditEntityData] = useState<{
    visible: Boolean
    entityData?: { entityId: number; groupId: number }
  }>({ visible: false })
  const [existingInvestorWithRowId, setExistingInvestorWithRowId] = useState<
    ExistingInvestorWithUniqueId[]
  >([])
  const [rowEmail, setRowEmail] = useState<string>()
  const [provisionInvestors, setProvisionInvestors] = useState<
    ExistingInvestor[]
  >([])
  const [errorTitle, setErrorTitle] = useState<string>(
    provisionUsersStatusMessages.errorTitleMessage
  )
  const [errorDetail, setErrorDetail] = useState<ReactNode | undefined>()
  const [modalDimension, setModalDimension] = useState<ModalDimensionProps>({})

  const [provisionPromise, setProvisionPromise] = useState<Promise<any>>()
  const searchRef = useRef<TextBox>(null)
  const [searchText, setSearchText] = useState('')
  const dataGridRef = useRef<DataGrid>(null)
  const [documentCount, setDocumentCount] = useState<number>(0)
  const [showCemInfo, setShowCemInfo] = useState<boolean>(false)
  const userApi = useUserApi()
  const {
    userCanAccessSubmitButton,
    userCanAccessProvisionButton,
    userCanAccessCEMInfoButton,
  } = useManageExistingInvestorsFunctions()
  const {filterChangedObservable, onOptionChangedHandler } = useClearFilterButtonEvents()

  /** Create Request to check whether the Page it authorized when retrieved 
   Redirect the Page if there is no permission */
  useAuthorizedPermission([
    permissionRequestAny(Permissions.PAGE_VISIBILITY_MANAGEXISTING),
  ])

  /** Validate  email and call API */
  const submitInvestorEmail = () => {
    // Validate form
    if (!validatorRef.current!.instance.validate().isValid) {
      return
    }

    // Exit if there's no email address.
    if (!emailAddress) {
      return
    }

    let investorWithRowId: ExistingInvestorWithUniqueId[] = []

    setLoading(true)
    setExistingInvestorWithRowId([])
    setSearchedEmailAddress(emailAddress)

    userApi
      .existingInvestorSource(emailAddress)
      .then((response: ExistingInvestor[]) => {
        response.forEach((investor, i) => {
          let investorRow: ExistingInvestorWithUniqueId = {
            existingInvestor: investor,
            uniqueId: i,
          }
          investorWithRowId.push(investorRow)
        })
        setExistingInvestorWithRowId(investorWithRowId)
        setDocumentCount(response.length)
      })
      .finally(() => {
        setLoading(false)
        //Clear the selected investors and the grid once user make emailIds search.
        setProvisionInvestors([])
        dataGridRef.current?.instance.clearSelection()
      })
  }
  // update Modal after user provisioning
  const updateErrorForPartialFailure = (
    provisionUserHeaders: ProvisionUserHeader[]
  ) => {
    // setErrorTitle for failure
    setErrorTitle(
      provisionUsersStatusMessages.errorTitleMessageOnPartialFailure
    )
    // Display failed list
    setErrorDetail(
      <ProvisionUsersFailedStatus provisionUserHeaders={provisionUserHeaders} />
    )
  }

  const onClose = () => {
    setIsVisible(false)
  }

  // Only those investors are selected which are not provisioned
  const onSelectionChanged = (data: SelectionChangedEvent) => {
    const investors = data.selectedRowsData.map(
      (item: ExistingInvestorWithUniqueId) => item.existingInvestor
    )

    // filter rows that user has no permission to provision and not provisioned before
    const existingInvestors = investors.filter(
      (investor) =>
        investor.status?.every((i) => i !== 'Provisioned') &&
        userCanAccessProvisionButton(investor.ipmNumber)
    )
    setProvisionInvestors(existingInvestors)
  }

  // Set the status of those investors as Provisioned, which has status === 'SUCCESS' in api response.
  const setInvestorProvisionStatus = (
    provisioningResult: ProvisionUserHeader[]
  ) => {
    if (provisioningResult && provisioningResult.length > 0) {
      provisioningResult.forEach((header) => {
        header.provisionUserRecords?.forEach((record) => {
          const provisionedRecordIndex = existingInvestorWithRowId.findIndex(
            (investor) =>
              record.status === 'SUCCESS' &&
              investor.existingInvestor.investorId === record.partnerEntityId &&
              investor.existingInvestor.ipmNumber === record.clientId &&
              investor.existingInvestor.emailAddress?.toLowerCase() ===
                record.emailAddress?.toLowerCase() &&
              investor.existingInvestor.clientGroupId === record.entityGroupId
          )
          if (provisionedRecordIndex !== -1) {
            existingInvestorWithRowId[
              provisionedRecordIndex
            ].existingInvestor.status?.push('Provisioned')
          }
        })
      })
      setExistingInvestorWithRowId(existingInvestorWithRowId)
    }
  }

  const provisionInvestor = async () => {
    try {
      setIsVisible(true)
      setLoading(true)
      let investors: ProvisionRequestDTO[] = []
      const apiPromises: Promise<ProvisionUserHeader>[] = []
      if (provisionInvestors) {
        investors = provisionInvestors.map((investor) => ({
          emailAddress: investor.emailAddress,
          entityId: investor.investorId,
          investorName: investor.investorName,
          ipmNumber: investor.ipmNumber,
          allocatingEntityName: investor.allocatingEntityName,
          clientName: investor.clientGroupName,
          clientId: investor.clientGroupId,
        }))
      }
      const provisionInvestorsPromise = userApi.provisionSelectedUser(investors)

      apiPromises.push(provisionInvestorsPromise)

      // Initialize watcher for provision users
      let provisionUserWatcher = new ProvisionUserByEmailsWatcher(apiPromises)
      provisionUserWatcher.initialize()

      // set promise for watcher to display spinner, to observe its progress
      setProvisionPromise(provisionUserWatcher.promise)

      // Wait for the promise to complete.  This is necessary
      //  for the catch portion of this block to function.
      // once investors are provisioned, update the status of the investors in the grid.
      await provisionUserWatcher.promise.then((provisioningResult) => {
        setInvestorProvisionStatus(provisioningResult)
      })
    } catch (err: any) {
      // If the watch throws an error with a ProvisionUserHeader, then
      //  this indicates a partial failure, and we need to set the
      //  spinner's error content appropriately.
      if (isProvisionUserHeaderList(err)) {
        setInvestorProvisionStatus(err)
        updateErrorForPartialFailure(err)
        // set modal dimension for partial failure
        setModalDimension({
          width: 800,
        })
      } else {
        // Since we don't have ProvisionUserHeader, that means some other error happened
        //  and we want the Spinner to reflect that.  Set the spinner's promise so it will show the error.
        setErrorTitle(provisionUsersStatusMessages.errorTitleMessage)
        setErrorDetail(`Error: ${err.message}`)

        setProvisionPromise(Promise.reject(err))
        //re-set modal dimension for default view
        setModalDimension({
          width: undefined,
        })
      }
    } finally {
      setLoading(false)
      setProvisionInvestors([])
      dataGridRef.current?.instance.clearSelection()
    }
  }

  /** Email text box and submit */
  const toolbarContent = (
    <Grid container alignItems='center' className='spaced-content'>
      <Grid item>
        <TextBox
          id='email-textbox'
          className='email-textbox'
          value={emailAddress}
          valueChangeEvent="keyup"
          onValueChanged={(data: ValueChangedEvent) =>
            setEmailAddress(data.value)
          }
          onEnterKey={submitInvestorEmail}
        >
          <Validator ref={validatorRef}>
            <RequiredRule message='Email is invalid' />
            <PatternRule
              ignoreEmptyValue={false}
              message='Email is invalid'
              pattern={multipleEmailWithoutWildcardPattern}
            />
          </Validator>
        </TextBox>
      </Grid>

      <Grid item>
        <Button
          elementAttr={{
            id: 'submit-button',
          }}
          text='Submit'
          type='default'
          useSubmitBehavior={true}
          disabled={!userCanAccessSubmitButton()}
          onClick={() => submitInvestorEmail()}
        />
      </Grid>
    </Grid>
  )

  /** Full toolbar content ie all components except the datagrid */
  const fullToolbarContent = (
    <Grid container alignItems='flex-end' className='padded-sides'>
      {/** this is grid contains textbox, searchbar and submit button, provision button */}
      <Grid item xs={12} md={8}>
        <Grid container alignItems='flex-end'>
          <Grid item>
            <Grid item xs={12}>
              <div className='instruction-list'>
                Email address must be added to Entity in Entity Manager before
                the email can be provisioned in RSM CEM.
              </div>
            </Grid>
            <Grid item xs={12}>
              {toolbarContent}
            </Grid>
            <Grid item xs={12} className='provision-button'>
              <Button
                text='Provision'
                type='default'
                useSubmitBehavior={false}
                // provision button will be disabled if there is any network call in progress
                // or if the email address in the search box is different from the email address in the grid
                // or if there are no investors selected in the grid to provision which has not been provisioned already
                // or if the user does not have permission to provision any of the selected investors
                disabled={isLoading || (searchedEmailAddress && searchedEmailAddress !== emailAddress) || provisionInvestors?.length === 0}
                onClick={() => {
                  provisionInvestor()
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/** this is grid contains datagrid searchbar and clear filter button */}
      <Grid
        item
        xs={12}
        md={4}
        alignItems='center'
        className='search-container'
      >
        <div className='clear-button'>
          <ClearFiltersButton
            gridRef={dataGridRef}
            filterChangedEvent={filterChangedObservable}
          />
        </div>

        <TextBox
          className='search-box'
          ref={searchRef}
          onValueChanged={(e) => setSearchText(e.value)}
          valueChangeEvent='keyup'
          placeholder={'Search...'}
          value={searchText}
          showClearButton={true}
        >
          <Button
            icon='search'
            type='normal'
            className='icon-button'
            onClick={() => {
              searchRef.current!.instance.focus()
            }}
          />
        </TextBox>
      </Grid>
    </Grid>
  )

  /** set action column of datagrid */
  const setActionColumn = (
    cellData: ICellData<ExistingInvestorWithUniqueId>
  ) => {
    // Create the ID for the button, so we can attach our context menu to it.
    // make this Id unique
    const buttonId = `btnMenuAction-${cellData.data?.uniqueId}`
    const contextMenu: MenuProps[] = [
      {
        text: 'Edit',
        icon: 'create',
        disabled:
        !cellData.data?.existingInvestor.investorId,
        action: () => {
          // Get the entity data to be edited.
          const entity = cellData.data!.existingInvestor

          // Before editing the entity, ensure we have the data we need.
          if (entity.investorId && entity.investorClientGroupId) {
            setEditEntityData({
              visible: true,
              entityData: {
                entityId: cellData.data!.existingInvestor.investorId!,
                groupId: cellData.data!.existingInvestor.investorClientGroupId!,
              },
            })
          }
        },
      },
      {
        text: 'CEM Info',
        icon: 'portrait',
        disabled: !cellData.data?.existingInvestor.investorId || !userCanAccessCEMInfoButton(),
        action: () => {
          setRowEmail(cellData.data!.existingInvestor.emailAddress)
          setShowCemInfo(true)
        },
      },
    ]
    return (
      <>
        <Button
          id={buttonId}
          icon='overflow'
          data-testid='context-button'
          stylingMode='text'
        ></Button>
        <ContextMenu
          dataSource={contextMenu}
          showEvent='click'
          target={`#${buttonId}`}
          itemRender={renderMenuItem}
          onItemClick={(e) => {
            const item = e.itemData as MenuProps | undefined
            if (item?.action) {
              item.action()
            }
          }}
        />
      </>
    )
  }

  /** Set Provisioned column value for sorting  */
  const setProvisionSortData = (
    cellData: ExistingInvestorWithUniqueId
  ): number => {
    if (cellData.existingInvestor.status?.some((i) => i === 'Provisioned')) {
      return Number.MIN_VALUE
    }
    return Number.MAX_VALUE
  }

  /** Set Documents column value for sorting  */
  const setDocumentSortData = (
    cellData: ExistingInvestorWithUniqueId
  ): number => {
    if (cellData.existingInvestor.status?.some((i) => i === 'Document')) {
      return Number.MIN_VALUE
    }
    return Number.MAX_VALUE
  }

  /** Set StateManager column value for sorting  */
  const setStateManagerSortData = (
    cellData: ExistingInvestorWithUniqueId
  ): number => {
    if (cellData.existingInvestor.status?.some((i) => i === 'StateManager')) {
      return Number.MIN_VALUE
    }
    return Number.MAX_VALUE
  }

  /** Set Client Name column value for sorting  */
  const setClientNameSortData = (cellData: ExistingInvestorWithUniqueId) => {
    if (cellData.existingInvestor.clientGroupName) {
      return cellData.existingInvestor.clientGroupName
    }
    return undefined
  }

  /** set provision checkbox column of datagrid */
  const setProvisionCheckBox = (
    cellData: ICellData<ExistingInvestorWithUniqueId>
  ) => {
    const isProvisioned: boolean =
      cellData.data?.existingInvestor.status?.some(
        (i) => i === 'Provisioned'
      ) ?? false
    return (
      <div className='checkbox-icon'>
        {isProvisioned && <CheckCircleOutlineOutlinedIcon color='primary' />}
        {!isProvisioned && <RadioButtonUncheckedOutlinedIcon color='primary' />}
      </div>
    )
  }

  /** set document checkbox column of datagrid */
  const setDocumentsCheckBox = (
    cellData: ICellData<ExistingInvestorWithUniqueId>
  ) => {
    const isDocumentsChecked: boolean =
      cellData.data?.existingInvestor.status?.some((i) => i === 'Document') ??
      false
    return (
      <div className='checkbox-icon'>
        {isDocumentsChecked && (
          <CheckCircleOutlineOutlinedIcon color='primary' />
        )}
        {!isDocumentsChecked && (
          <RadioButtonUncheckedOutlinedIcon color='primary' />
        )}
      </div>
    )
  }

  /** set State Manager column of datagrid */
  const setStateManagerCheckBox = (
    cellData: ICellData<ExistingInvestorWithUniqueId>
  ) => {
    const isStateManagerChecked: boolean =
      cellData.data?.existingInvestor.status?.some(
        (i) => i === 'StateManager'
      ) ?? false
    return (
      <div className='checkbox-icon'>
        {isStateManagerChecked && (
          <CheckCircleOutlineOutlinedIcon color='primary' />
        )}
        {!isStateManagerChecked && (
          <RadioButtonUncheckedOutlinedIcon color='primary' />
        )}
      </div>
    )
  }

  /** Get the list of records displayed in the child datagrid */
  const onContentReady = (e: EventInfo<dxDataGrid>) => {
    setDocumentCount(e.component.totalCount())
  }

  // Creates a unique key for a cell in the data grid, to prevent flickering.
  // two rows with same key leads to flickering issue, in order to make it unique
  // we take row ID from response and attach column name in order to make it unique
  const getId =
    (prefix: string) => (data: ICellData<ExistingInvestorWithUniqueId>) =>
      `${prefix}-${data.data?.uniqueId}`

  /** call when  */
  const onOptionChanged = (e: OptionChangedEventInfo<dxDataGrid>) => {
    onOptionChangedHandler(e)
  }  

  return (
    <>
      <div className='manage-existing-investors'>
        <div>{fullToolbarContent}</div>
        <div className='ip-table'>
          <DataGrid
            ref={dataGridRef}
            showBorders={true}
            keyExpr='uniqueId'
            noDataText={
              isLoading &&
              existingInvestorWithRowId.length === 0 &&
              documentCount === 0
                ? 'Loading...'
                : !isLoading &&
                  (existingInvestorWithRowId.length === 0 ||
                    documentCount === 0)
                ? 'No Data Found.'
                : ''
            }
            dataSource={existingInvestorWithRowId}
            columnAutoWidth={true}
            height={dgheight}
            repaintChangesOnly={true}
            showColumnLines={false}
            showRowLines={true}
            wordWrapEnabled={false}
            onCellPrepared={addToolTipOnCellPrepared}
            onContentReady={onContentReady}
            onSelectionChanged={onSelectionChanged}
            onOptionChanged={
              onOptionChanged
            }
          >
            <SearchPanel
              visible={false}
              text={searchText}
              width={350}
              placeholder='Search...'
            />
            <Scrolling mode='virtual' rowRenderingMode='virtual' />
            <Sorting mode='single' />
            <Selection mode='multiple' showCheckBoxesMode='always' />
            <LoadPanel enabled={false} />
            <HeaderFilter visible={true} allowSearch={true} />
            <Column
              dataField='existingInvestor.emailAddress'
              caption='Email Address'
              allowSearch={true}
              allowSorting={true}
              defaultSortOrder='desc'
              width={'13%'}
              cellKeyFn={getId('EmailAddress')}
            ></Column>
            <Column
              dataField='existingInvestor.investorId'
              caption='Entity Id'
              alignment='left'
              allowSearch={true}
              allowSorting={true}
              width={'8%'}
              cellKeyFn={getId('entityId')}
            />
            <Column
              dataField='existingInvestor.investorName'
              caption='Investor Name'
              alignment='left'
              allowSearch={true}
              allowSorting={true}
              width={'16%'}
              cellKeyFn={getId('InvestorName')}
            />
            <Column
              dataField='existingInvestor.clientGroupName'
              caption='Client Name'
              alignment='left'
              allowSearch={true}
              allowSorting={true}
              width={'15%'}
              calculateSortValue={setClientNameSortData}
              cellKeyFn={getId('ClientName')}
            />
            <Column
              dataField='existingInvestor.ipmNumber'
              caption='IPM Number'
              alignment='left'
              allowSearch={true}
              allowSorting={true}
              width={'10%'}
              cellKeyFn={getId('IpmNumber')}
            />
            <Column
              dataField='existingInvestor.allocatingEntityName'
              caption='Allocating Entity Name'
              alignment='left'
              allowSearch={true}
              allowSorting={true}
              width={'15%'}
              cellKeyFn={getId('AllocatingEntityName')}
            />
            <Column
              caption='Provisioned'
              alignment='center'
              allowSearch={false}
              allowSorting={true}
              allowFiltering={false}
              cellRender={setProvisionCheckBox}
              calculateSortValue={setProvisionSortData}
              width={'6%'}
              cellKeyFn={getId('ProvisionCheckbox')}
            />
            <Column
              caption='Documents'
              alignment='center'
              allowSearch={false}
              allowSorting={true}
              allowFiltering={false}
              cellRender={setDocumentsCheckBox}
              calculateSortValue={setDocumentSortData}
              width={'6%'}
              cellKeyFn={getId('DocumentManagerCheckbox')}
            />
            <Column
              caption='State Manager'
              alignment='center'
              allowSearch={false}
              allowSorting={true}
              allowFiltering={false}
              cellRender={setStateManagerCheckBox}
              calculateSortValue={setStateManagerSortData}
              width={'7%'}
              cellKeyFn={getId('StateManagerCheckbox')}
            />
            <Column
              caption='Action'
              cellRender={setActionColumn}
              allowSearch={false}
              allowSorting={false}
              allowFiltering={false}
              width={'5%'}
              cellKeyFn={getId('Action')}
            />
          </DataGrid>
        </div>
        <CemInfoModal
          onCancel={() => {
            setShowCemInfo(false)
            setRowEmail(undefined)
          }}
          email={rowEmail!}
          isVisible={showCemInfo}
        />
      </div>
      <SpinnerModal
        visible={isVisible}
        closeOnSuccess={false}
        inProgressTitleMessage={
          provisionUsersStatusMessages.inProgressTitleMessage
        }
        inProgressMessage={provisionUsersStatusMessages.inProgressMessage}
        successTitleMessage={provisionUsersStatusMessages.successTitleMessage}
        successMessage={provisionUsersStatusMessages.successMessage}
        errorTitleMessage={errorTitle}
        errorMessage={errorDetail}
        width={modalDimension.width}
        onClose={(successFlag?: boolean) => {
          onClose()
          setModalDimension({
            width: undefined,
          })
        }}
        apiAction={provisionPromise}
      />
      {editEntityData.visible && (
        <AddEditEntity
          {...editEntityData.entityData!}
          onModalClose={(updatedEntity?: EntityView2) => {
            if (updatedEntity) {
              // Update the entity in the data grid.
              setExistingInvestorWithRowId(
                produce((draft) => {
                  // There may be more than one listing of the same entity.
                  //  Find and update them.
                  draft.forEach((rowData) => {
                    const investor = rowData.existingInvestor

                    if (investor.investorId === updatedEntity.id) {
                      if (updatedEntity.entityType === 'Individual') {
                        investor.investorName = `${updatedEntity.firstName} ${updatedEntity.lastName}`
                      } else {
                        investor.investorName = updatedEntity.entityName
                      }
                    }
                  })
                })
              )
            }

            // Close the modal.
            setEditEntityData({ visible: false })
          }}
        ></AddEditEntity>
      )}
    </>
  )
}
