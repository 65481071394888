export type FileStatus =
  | 'UploadInProgress'
  | 'UploadDone'
  | 'UploadError'
  | 'EmailInProgress'
  | 'EmailDone'
  | 'EmailError'
  | 'Inactive'
  | 'Placeholder'

export const commonFileTags: { [key: string]: string } = {
  taxYear: 'TaxYear',
  displayName: 'DisplayName',
  DocumentCode: 'DocumentCode',
  TaxYear: 'TaxYear',
  PartnershipName: 'PartnershipName',
  InvestorId: 'InvestorId',
  DocumentSubType: 'DocumentSubType',
  UploadedDocumentSubType: 'UploadedDocumentSubType',
  UploadedTaxYear: 'UploadedTaxYear',
  EmployerId: 'EmployerId',
  PartnerId: 'PartnerId',
  PartnerName: 'PartnerName',
  PartnershipId: 'PartnershipId',
  EntityType: 'EntityType',
  EntityName: 'EntityName',
  MatchingCriteria: 'MatchingCriteria'
}

export enum SearchTermValidationTypes {
  Valid,
  TooShort,
  NoContent,
}

export enum DisplayFileStatus {
  Inactive = 'Inactive',
  Published = 'Published',
  ReadyToPublish = 'Ready To Publish',
  ReadyToPublishWithError = 'Ready To Publish With Errors',
  NotReadyToPublish = 'Not Ready To Publish',
  PublishedWithError = 'Published With Error',
  MaliciousFile = 'Malicious File',
}

/** Parent file edit model */
export interface EditParentFileModel {
  fileId: number
  documentFileName: string
  taxYear: string
  fileType: string
  emailTemplate: string
  addedBy: string
  dateAdded: string
}

/** QEE model */
export interface QuickEditEntryModel {
  fileId: number
  entityName: string
  emails?: string[]
  einNumber: string
  partnerEntityId: number
  email?: string
}

/** Email edit model */
export interface QuickEditEmailModel {
  email: string
  id: number
}

/** List of DocumentSubType values that can be applied to a DocumentSubType FileTag. */
export const fileDocumentSubTypes = [
  'K-1 Final',
  'K-1 Estimate',
  'K-1 Draft',
  'K-1 State Only',
  'Other – 8805',
  'Other – 1042',
  'Other – Basis Letter',
  'Other – Withholding',
  'Other Document',
]

/** List of Email Template values. */
export const emailTemplates = ['Default', 'Client Group', 'Entity', 'File']

export interface CommonFilterHeader {
  key?: any
  value: any
  text: string
}

export interface CreateDateHeaderFilter extends Array<CommonFilterHeader> { }

/** Text Change event */
export interface OptionChangedEvent {
  value: string
  name: string
}

/** Context Menu Item Props */
export interface MenuProps {
  icon: any //Using generic type here to hold material Managed Accounts icon component.
  text: string
  disabled?: boolean
  items?: MenuProps[]
  action?: (...args: any[]) => void
}

/** Email validation status and message  */ 
export interface EmailColumnValidation {
  isError: boolean
  message: string
}