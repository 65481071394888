import { useCallback, useEffect, useRef, useState } from 'react'
import {
  DataGrid,
  Column,
  Sorting,
  Selection,
  Scrolling,
  LoadPanel,
  HeaderFilter,
  SearchPanel,
  Item,
  Toolbar,
} from 'devextreme-react/data-grid'
import { dgheight, ICellData, MenuProps } from '../../model/file-model'
import { useParams } from 'react-router-dom'
import { PartnerDetails } from '../../api-client/investor-portal-client'
import { DateFormat } from '../../model/date-formats'
import {
  formatDate,
  getHeaderFilterDates,
  getFormattedDateWithNeverOption,
  getFormattedDateWithOutTimeStampWithNeverOption,
} from '../investor-files/fileUtilities'
import { useRecoilValue } from 'recoil'
import { Grid } from '@mui/material'
import { Button, ContextMenu } from 'devextreme-react'
import { getLandingPageRoute } from '../utility/route-creation'
import { partnerDetailsListAtom } from '../../state/atom'
import { useFileClientApi } from '../../hooks/use-file-api'
import './client-group-status.scss'
import { EventInfo } from 'devextreme/events'
import dxDataGrid from 'devextreme/ui/data_grid'
import { HeaderFilterDataSourceOptions } from '../../../types/types'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { ActivtyReport } from '../investor-files/activityReport'
import { ManageEmailTemplateDialog } from '../modal/emailTemplateDialog/manageEmailTemplateDialog'
import { taxYearState } from '../../common-components/banners/tax-year-control/tax-year-control-state'
import { ClearFiltersButton, useClearFilterButtonEvents } from '../../common-components/clear-filter/clear-filters-button'
import { useNavigate } from '../../hooks/useNavigationGuard'
import { DISMOUNT_ABORT_REASON } from '../utility/abort-constants'
import { useClientGroupPermissionFunctions } from './use-client-group-permissions-functions'

/** Set context menu text and css class */
export function renderMenuItem(e: MenuProps) {
  let icon = <span className='material-icons-outlined'>{e.icon}</span>
  switch (e.icon) {
    case 'textdocument':
      icon = <DescriptionOutlinedIcon />
      break
  }
  return (
    <>
      {icon}
      {e.text}
    </>
  )
}

export const InvestorStatusTable = () => {
  const { details, isLoading } = useRecoilValue(partnerDetailsListAtom)
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false)
  const dataGridRef = useRef<DataGrid>(null)
  const navigate = useNavigate()
  const { groupId } = useParams() as { groupId: string }
  const { taxyear } = useRecoilValue(taxYearState)
  const clientApi = useFileClientApi()
  const {filterChangedObservable, onOptionChangedHandler } = useClearFilterButtonEvents()
  
  const { userCanAccessInvestorReport, userCanEditClientTemplate } =
    useClientGroupPermissionFunctions()

  useEffect(() => {
    const abortController = new AbortController()
    setIsDataLoading(true)
    clientApi
      .getInvestorDetails(parseInt(groupId), taxyear!, abortController.signal)
      .finally(() => setIsDataLoading(false))
    return () => {
        abortController.abort(DISMOUNT_ABORT_REASON)
    }
  }, [groupId, taxyear])

  const [documentCount, setDocumentCount] = useState<number>(0)
  const [showActivityReportModal, setShowActivityReportModal] = useState(false)
  const groupIdValue = parseInt(groupId)
  const [showEmailTemplate, setShowEmailTemplate] = useState(false)

  //ellipses menu options
  const masterContextMenu: MenuProps[] = [
    {
      text: 'Manage Email Template',
      icon: 'create',
      action: () => {
        setShowEmailTemplate(true)
      },
    },
    {
      text: 'Investor Activity Report',
      icon: 'textdocument',
      disabled: !userCanAccessInvestorReport(groupIdValue),
      action: () => {
        setShowActivityReportModal(true)
      },
    },
  ]

  // setting activity Report Modal to false
  const activityReportModalClose = () => {
    setShowActivityReportModal(false)
  }

  const setLastActivity = (cellData: ICellData<PartnerDetails>) => {
    if (cellData.rowType === 'data' && cellData.data?.lastActivityDate) {
      const lastActivityDate = new Date(cellData.data?.lastActivityDate)
      const displayDate =
        lastActivityDate.getFullYear() <= 1
          ? 'never'
          : formatDate(lastActivityDate)
      let titleDate = getFormattedDateWithNeverOption(lastActivityDate,DateFormat.longDateTimeFormat)      
      return (
        <span
          title={
            titleDate
          }
        >
          {displayDate}
        </span>
      )
    }
    return ''
  }

  /** Set Last Activity column value for sorting  */
  const setLastActivitySortData = (file: PartnerDetails) => {
    const lastActivityDate = new Date(file.lastActivityDate!)
    if (lastActivityDate.getFullYear() <= 1) {
      return -1
    }
    return lastActivityDate.valueOf()
  }

  //calculate the last activity date for 'lastActivityDate' column
  const calculateLastActivityDate = (cellData: PartnerDetails) => {
    return getFormattedDateWithNeverOption(cellData.lastActivityDate,DateFormat.isoDateTimeFormat)
  }

  /** Get the list of records displayed in the datagrid */
  const onContentReady = (e: EventInfo<dxDataGrid>) => {
    setDocumentCount(e.component.totalCount)
    setFilteredAndSortedRowsCount()
  }

  /** Updates the list of dates displayed in the date header filter */
  function createdDateHeaderFilter(options: HeaderFilterDataSourceOptions) {
    options.dataSource.paginate = false
    options.dataSource.postProcess = getHeaderFilterDates
  }

  /** Calculate Matching Criteria colum Filter expression for 'DateAdded' column filter */
  function calculateLastActivityDateFilterExpression(
    this: Column,
    value: any,
    selectedFilterOperations: string,
    target: string
  ) {
    if (target === 'headerFilter') {
      return [[getFormattedDate, '=', value]]
    }

    return this.defaultCalculateFilterExpression(value, selectedFilterOperations, target);
  }

  function getFormattedDate(rowData: PartnerDetails) {
    return getFormattedDateWithOutTimeStampWithNeverOption(rowData.lastActivityDate,DateFormat.shortDateFormat);
  }
  

  /** Sets filtered records displayed in grid view */
  const setFilteredAndSortedRowsCount = useCallback(() => {
    const grid = dataGridRef.current!.instance
    const filterExpr = grid.getCombinedFilter(true)
    const dataSource = grid.getDataSource()
    const loadOptions = dataSource.loadOptions()
    dataSource.store().load({
      filter: filterExpr,
      sort: loadOptions.sort,
      group: loadOptions.group,
    })
  }, [dataGridRef])

  return (
    <div className='ip-table'>
      <DataGrid
        showBorders={true}
        keyExpr='partnerId'
        noDataText={
          isLoading && details.length === 0 && documentCount === 0
            ? 'Loading...'
            : !isLoading && (details.length === 0 || documentCount === 0)
            ? 'No Data Found.'
            : ''
        }
        dataSource={details}
        columnAutoWidth={false}
        height={dgheight}
        repaintChangesOnly={true}
        showColumnLines={false}
        onContentReady={onContentReady}
        showRowLines={true}
        wordWrapEnabled={false}
        ref={dataGridRef}
        allowColumnResizing={true}
        onOptionChanged={onOptionChangedHandler}
      >
        <SearchPanel visible={true} width='400px' placeholder='Search Investor Name' />
        <Scrolling mode='virtual' rowRenderingMode='virtual' />
        <Selection mode='multiple' showCheckBoxesMode='always' />
        <Sorting mode='single' />
        <LoadPanel enabled={false} />
        <HeaderFilter visible={true} allowSearch={true} />
        <Toolbar>
          <Item location='before'>
            <Grid
              container
              item
              xs={10}
              minWidth={800}
              justifyContent={'flex-start'}
            >
              <Grid item xs={1} className='status-button-container'>
                <Button
                  onClick={() => navigate(getLandingPageRoute())}
                  stylingMode='outlined'
                  data-testid='btnBack'
                >
                  <span className='dx-icon-arrowleft'></span>
                </Button>
              </Grid>
              <Grid item xs={2} className='status-label-container'>
                <span>Document Status</span>
              </Grid>
            </Grid>
          </Item>
          {/* CLEAR FILTERS */}
          <Item name='clearFilters'>
            <ClearFiltersButton
              gridRef={dataGridRef}
              filterChangedEvent={filterChangedObservable}
            />
          </Item>
          <Item name='searchPanel' />
          <Item location='after'>
            <Grid container minWidth={65} justifyContent={'flex-end'}>
              <Button
                stylingMode='outlined'
                id='masterActionBtn'
                data-testid='btnMasterAction'
              >
                <span className='dx-icon-overflow'></span>
              </Button>
              <ContextMenu
                width={150}
                dataSource={masterContextMenu}
                showEvent='mouseenter'
                target='#masterActionBtn'
                itemRender={renderMenuItem}
                onItemClick={(e) => {
                  const item = e.itemData as MenuProps | undefined
                  if (item?.action) {
                    item.action()
                  }
                }}
              />
            </Grid>
          </Item>
        </Toolbar>
        <Column
          dataField='partnerName'
          caption='Investor Name'
          allowSearch={true}
          allowSorting={true}
          defaultSortOrder='asc'
          minWidth={'48%'}
        ></Column>
        <Column
          dataField='documentCount'
          caption='# of Documents'
          alignment='left'
          allowSearch={false}
          allowSorting={true}
          width={'12%'}
        />
        <Column
          dataField='numberOfEntities'
          caption='# of Allocating Entities'
          alignment='left'
          allowSearch={false}
          allowSorting={true}
          width={'10%'}
        />
        <Column
          dataField='numberOfContacts'
          caption='# of Contacts'
          alignment='left'
          allowSearch={false}
          allowSorting={true}
          width={'10%'}
        />
        <Column
          calculateCellValue={calculateLastActivityDate}
          caption='Last Activity'
          alignment='center'
          cellRender={setLastActivity}
          calculateSortValue={setLastActivitySortData}
          allowSearch={false}
          allowSorting={true}
          width={'20%'}
          allowFiltering={true}
          calculateFilterExpression={calculateLastActivityDateFilterExpression}
        >
          <HeaderFilter dataSource={createdDateHeaderFilter} />
        </Column>
      </DataGrid>
      {showActivityReportModal && (
        <ActivtyReport
          entityGroupId={groupIdValue}
          onCancel={activityReportModalClose}
          onConfirm={activityReportModalClose}
        ></ActivtyReport>
      )}
      {showEmailTemplate && (
        <ManageEmailTemplateDialog
          onCancel={() => setShowEmailTemplate(false)}
          partnershipId={0}
          entityGroupId={groupIdValue}
          parentFileId={0}
        ></ManageEmailTemplateDialog>
      )}
    </div>
  )
}
