import { env as env2 } from '@rsmus/react-env-image'

/*
    The following are values found in either the .env files or in the environment variables on the machine.
    Environment variables from the machine are preferred, but .env files can be useful in development.  In such
    cases, use .env.development, since that will not propagate to the production environment.
*/

// This is a temporary work around to allow tests to succeed.  The current env function will throw
//  errors if the window object doesn't have a config property.  The offending NPM package should be
//  updated soon, and this if-block can be removed at that time.
let env = env2
if ((window as any) != null && (window as any).config == null) {
  env = function () {
    return undefined
  }
}

export const REACT_APP_IDMAUTHENTICATIONCLIENTID = env(
  'REACT_APP_IDMAUTHENTICATIONCLIENTID'
)
export const REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS = env(
  'REACT_APP_OIDCSILENTREFRESHOFFSETTIMEINSECONDS'
)
export const REACT_APP_IDMAUTHENTICATIONAUTHORITY = env(
  'REACT_APP_IDMAUTHENTICATIONAUTHORITY'
)
export const REACT_APP_URLADMINCENTER = env('REACT_APP_URLADMINCENTER')
export const REACT_APP_IDMAUTHENTICATIONAUTHORIZATIONSERVICEURL = env(
  'REACT_APP_IDMAUTHENTICATIONAUTHORIZATIONSERVICEURL'
)
export const REACT_APP_TSAAPPURL = env('REACT_APP_IDMAUTHENTICATIONCLIENTID')
export const REACT_APP_ENGAGEMENTMANAGERURL = env(
  'REACT_APP_ENGAGEMENTMANAGERURL'
)
export const REACT_APP_INVESTORPORTALURL = env('REACT_APP_INVESTORPORTALURL')
export const REACT_APP_IPMURL = env('REACT_APP_IPMURL')
export const REACT_APP_IDMAUTHENTICATIONEULASERVICEURL = env(
  'REACT_APP_IDMAUTHENTICATIONEULASERVICEURL'
)
export const REACT_APP_REQUESTASSISTANCEPHONEINTERNAL = env(
  'REACT_APP_REQUESTASSISTANCEPHONEINTERNAL'
)
export const REACT_APP_REQUESTASSISTANCEPHONEEXTERNAL = env(
  'REACT_APP_REQUESTASSISTANCEPHONEEXTERNAL'
)
export const REACT_APP_REQUESTASSISTANCEEMAIL = env(
  'REACT_APP_REQUESTASSISTANCEEMAIL'
)
export const REACT_APP_INVESTORPORTALPUBLICDOCUMENTSURL = env(
  'REACT_APP_INVESTORPORTALPUBLICDOCUMENTSURL'
)
export const REACT_APP_TSAAGGREGATORURL = env('REACT_APP_TSAAGGREGATORURL')
export const REACT_APP_APPINSIGHTSINSTRUMENTATIONKEY = env(
  'REACT_APP_APPINSIGHTSINSTRUMENTATIONKEY'
)
export const REACT_APP_TSAAPIBASEURL = env('REACT_APP_TSAAPIBASEURL')
export const REACT_APP_ENTITYMANAGERURL = env('REACT_APP_ENTITYMANAGERURL')
export const RSM_ENVIRONMENT_APP_CONFIG_URL = env(
  'RSM_ENVIRONMENT_APP_CONFIG_URL'
)
export const RSM_ENVIRONMENT_CLIENT_ID = env('RSM_ENVIRONMENT_CLIENT_ID')
export const RSM_ENVIRONMENT_CLIENT_SECRET = env(
  'RSM_ENVIRONMENT_CLIENT_SECRET'
)
export const RSM_ENVIRONMENT_TENANT_ID = env('RSM_ENVIRONMENT_TENANT_ID')
export const RSM_ENVIRONMENT_TYPE = env('RSM_ENVIRONMENT_TYPE')
export const RSM_ENVIRONMENT_USER_NAME = env('RSM_ENVIRONMENT_USER_NAME')
export const REACT_APP_INVESTORPORTAL_CLIENTURL = env(
  'REACT_APP_INVESTORPORTAL_CLIENTURL'
)
export const REACT_APP_IDM_INTERNAL_USERS_GROUPS = env(
  'REACT_APP_IDM_INTERNAL_USERS_GROUPS'
)
export const REACT_APP_ENTITYMANAGER_CLIENTURL = env(
  'REACT_APP_ENTITYMANAGER_CLIENTURL'
)
//Idle Timeout value for External Users in minutes
export const REACT_APP_EXTERNAL_USER_TIMEOUT = env(
  'REACT_APP_EXTERNAL_USER_TIMEOUT'
)
export const REACT_APP_INVESTORPORTALDOCUMENTMANAGERFILESURL = env(
  'REACT_APP_INVESTORPORTALDOCUMENTMANAGERFILESURL'
)

export const REACT_APP_CEM_ROLES_AUTHORITY = env('REACT_APP_CEM_ROLES_AUTHORITY')

export const REACT_APP_CEM_APP_URL = env('REACT_APP_CEM_APP_URL')

/** We are using this Id to fetch Terms of Use from EULA */
export const REACT_APP_EULA_TERMS_OF_USE_ID = env('REACT_APP_EULA_TERMS_OF_USE_ID')

// The REACT_APP_FEATURES_STATEMANAGER value must be a boolean.  Since the configuration
//  only returns strings, we must take care of the conversion ourself.
let stateManagerFeature = env('REACT_APP_FEATURES_STATEMANAGER') as boolean | undefined | string
// Check if the value is a string.  If not, let it be as-is.
if (typeof stateManagerFeature === 'string') {
  // Convert true as required.  Otherwise, regard any other value as false.
  if (stateManagerFeature.toLocaleLowerCase() === 'true') {
    stateManagerFeature = true
  } else {
    stateManagerFeature = false
  }
}
export const REACT_APP_FEATURES_STATEMANAGER = stateManagerFeature

// The REACT_APP_FEATURES_ENTITYMANAGER value must be a boolean.  Since the configuration
//  only returns strings, we must take care of the conversion ourself.
let entityManagerFeature = env('REACT_APP_FEATURES_ENTITYMANAGER') as boolean | undefined | string
// Check if the value is a string.  If not, let it be as-is.
if (typeof entityManagerFeature === 'string') {
  // Convert true as required.  Otherwise, regard any other value as false.
  if (entityManagerFeature.toLocaleLowerCase() === 'true') {
    entityManagerFeature = true
  } else {
    entityManagerFeature = false
  }
}
export const REACT_APP_FEATURES_ENTITYMANAGER = entityManagerFeature

// The REACT_APP_FEATURES_ENTITYMANAGER value must be a boolean.  Since the configuration
//  only returns strings, we must take care of the conversion ourself.
let manageExistingInvestorsFeature = env('REACT_APP_FEATURES_MANAGEEXISTING') as boolean | undefined | string
// Check if the value is a string.  If not, let it be as-is.
if (typeof manageExistingInvestorsFeature === 'string') {
  // Convert true as required.  Otherwise, regard any other value as false.
  if (manageExistingInvestorsFeature.toLocaleLowerCase() === 'true') {
    manageExistingInvestorsFeature = true
  } else {
    manageExistingInvestorsFeature = false
  }
}
export const REACT_APP_FEATURES_MANAGEEXISTING = manageExistingInvestorsFeature

// The REACT_APP_FEATURES_DMS value must be a boolean.  Since the configuration
//  only returns strings, we must take care of the conversion ourself.
let dmsFeature = env('REACT_APP_FEATURES_DMS') as boolean | undefined | string
// Check if the value is a string.  If not, let it be as-is.
if (typeof dmsFeature === 'string') {
  // Convert true as required.  Otherwise, regard any other value as false.
  if (dmsFeature.toLocaleLowerCase() === 'true') {
    dmsFeature = true
  } else {
    dmsFeature = false
  }
}
export const REACT_APP_FEATURES_DMS = dmsFeature

